*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

:root {
    --primary_color_shade0:#E1F5FE;
    --primary_color_shade1:#B3E5FC;
    --primary_color_shade2:#81D4FA;
    --primary_color_shade3:#4FC3F7;
    --primary_color_shade4:#29B6F6;
    --primary_color_shade5:#03A9F4;
    --primary_color_shade6:#039BE5;
    --primary_color_shade7:#0288D1;
    --primary_color_shade8:#0277BD;
    --primary_color_shade9:#01579B;

    --highlight_color1:#FE488B;
    --highlight_color2:#FFF307;
    --highlight_color3:#0765D6;
    --highlight_color4:#45DD03;

    --card_orange:#FF7043;
    --card_green:#89BE4C;
    --card_blue:#39AFD5;
    --card_deep_purple:#7e57c2;
    --card_voilet:#702b56;
    --card_red:#ef5350;
    --card_magenta:#CD014D;
    --card_cyan_green:#02BBA1;
    --card_amber:#FFB300;
    --card_burnt_orange:#EE4E34;
    --card_peach:#FCEDDA;

    --green_dark:#33b536;
    --pesto_green:#00AA8D;
    --pesto_green_lighter:#00BF9A;
    --pesto_green_darker:#008975;
    --leaf_green:#0C7300;
    --white:#FFFFFF;
    --black:#000;
    --blue:#0765D6;
    --voilet:#702b56;
    --holiday:#FFA000;
    --transparent:#FFffffff;

    --disalbed:#F5F5F5;
    --background_grey:#E0E0E0;
    --background_grey_dark:#BDBDBD;
    --grey0:#FAFAFA;
    --grey1:#F5F5F5;
    --grey2:#EEEEEE;
    --grey3:#E0E0E0;
    --grey4:#BDBDBD;
    --grey5:#9E9E9E;
    --grey_light:#dad9de;
    --green_light:#e4f9e4;

    --black9:#191919;
    --black8:#333333;
    --black7:#4c4c4c;
    --black6:#666666;
    --black5:#7f7f7f;
    --black4:#999999;
    --black3:#b2b2b2;
    --black2:#cccccc;
    --black1:#e5e5e5;

    --amber0:#FFF8E1;
    --amber1:#FFECB3;
    --amber2:#FFE082;
    --amber3:#FFD54F;
    --amber4:#FFCA28;
    --amber5:#FFC107;
    --amber6:#FFB300;
    --amber7:#FFA000;
    --amber8:#FF8F00;
    --amber9:#FF6F00;

    --green0:#AAFD86;
    --green1:#71FC36;
    --green2:#45DD03;
    --green3:#38B503;
    --green4:#2C8D02;
    --green5:#257502;
    --green6:#1F6402;
    --green7:#195001;
    --green8:#133C01;
    --green9:#0C2801;

    --Deep_Purple0:#ede7f6;
    --Deep_Purple1:#d1c4e9;
    --Deep_Purple2:#b39ddb;
    --Deep_Purple3:#9575cd;
    --Deep_Purple4:#7e57c2;
    --Deep_Purple5:#673ab7;
    --Deep_Purple6:#5e35b1;
    --Deep_Purple7:#512da8;
    --Deep_Purple8:#4527a0;
    --Deep_Purple9:#311b92;

    --radish0:#FDEDED;
    --radish1:#FBDADC;
    --radish2:#F6B6B8;
    --radish3:#F4A4A6;
    --radish4:#F07F83;
    --radish5:#EE6E73;
    --radish6:#EA484E;
    --radish7:#E5242A;
    --radish8:#C9181D;
    --radish9:#A41318;
    --radish10:#800F13;

   --red0:#ffebee;
   --red1:#ffcdd2;
   --red2:#ef9a9a;
   --red3:#e57373;
   --red4:#ef5350;
   --red5:#f44336;
   --red6:#e53935;
   --red7:#d32f2f;
   --red8:#c62828;
   --red9:#b71c1c;
    --navy_blue0:#97ADD8;
    --navy_blue1:#7995CD;
    --navy_blue2:#5B7EC2;
    --navy_blue3:#4268B3;
    --navy_blue4:#375695;
    --navy_blue5:#2A4272;
    --navy_blue6:#273C68;
    --navy_blue7:#213459;
    --navy_blue8:#1C2B4A;
    --navy_blue9:#16233C;


    --light_blue0:#E1F5FE;
    --light_blue1:#B3E5FC;
    --light_blue2:#81D4FA;
    --light_blue3:#4FC3F7;
    --light_blue4:#29B6F6;
    --light_blue5:#03A9F4;
    --light_blue6:#039BE5;
    --light_blue7:#0288D1;
    --light_blue8:#0277BD;
    --light_blue9:#01579B;



    --deeporange0:#FBE9E7;
    --deeporange1:#FFCCBC;
    --deeporange2:#FFAB91;
    --deeporange3:#FF8A65;
    --deeporange4:#FF7043;
    --deeporange5:#FF5722;
    --deeporange6:#F4511E;
    --deeporange7:#E64A19;
    --deeporange8:#D84315;
    --deeporange9:#BF360C;


    --indian_saffron0:#ffcbab;
   --indian_saffron1:#ffb384;
    --indian_saffron2:#ff9b5d;
    --indian_saffron3:#ff8f49;
    --indian_saffron4:#ff8336;
    --indian_saffron5:#ff7722;
   --indian_saffron6:#ff6b0e;
    --indian_saffron7:#fa6000;
   --indian_saffron8:#e65900;
   --indian_saffron9:#d35100;


    --rad-50: 50%;
    --rad-100: 100%;
  }
  p {
    margin-bottom: 0px;    
  }
  .row {
    padding: 0px;
    margin: 0px;
  }
 
  
.cursor{
    cursor:pointer;
}
.padding_0{
    padding: 0px;
}
.padding_5{
    padding: 5px;
}
.padding_10{
    padding: 10px;
}

.margin_0{
    margin: 0px;
}
.margin_3{
    margin: 3px;
}
.margin_5{
    margin: 5px;
}
.margin_10{
        margin: 10px;
}
.height_match_parent{
    height:100%;
    
}
.width_match_parent{
    width:100%;
    
}
.match_parent{
    height:100%;
    width:100%;
}
.width_fill_parent{
    width: 100vw;
}
.txt_white{
    color: var(--white);
}
.black{
    color: var(--black);
}
.black9{
    color: var(--black9);
}
.black8{
    color: var(--black8);
}
.black7{
    color: var(--black7);
}
.black6{
    color: var(--black6);
}
.black5{
    color: var(--black5);
}
.black4{
    color: var(--black4);
}
.bg_grid {
    border: 1px solid var(--grey4);  
}
.h_30{
    height:30px;
}
.h_35{
    height:35px;
}
.h_40{
    height:40px;
}
.h_45{
    height:45px;
}
.h_50{
    height:50px;
}
.h_55{
    height:55px;
}
.h_60{
    height:60px;
}
.h_65{
    height:65px;
}
.h_70{
    height:70px;
}
.h_75{
    height:75px;
}
.h_80{
    height:80px;
}
.h_85{
    height:85px;
}
.h_90{
    height:90px;
}
.h_95{
    height:95px;
}
.h_100{
    height:100px;
}

.w_30{
    width:30px;
}
.w_35{
    width:35px;
}
.w_40{
    width:40px;
}
.w_45{
    width:45px;
}
.w_50{
    width:50px;
}
.w_55{
    width:55px;
}
.w_60{
    width:60px;
}
.w_65{
    width:65px;
}
.w_70{
    width:70px;
}
.w_75{
    width:75px;
}
.w_80{
    width:80px;
}
.w_85{
    width:85px;
}
.w_90{
    width:90px;
}
.w_95{
    width:95px;
}
.w_100{
    width:100px;
}

.bg_grid_white {
    border: 1px solid var(--white);  
}
.bg_grid_white_2 {
    border: 2px solid var(--white);  
}
.bg_grid_primary {
    border: 1px solid var(--primary_color_shade4);  
}
.bg_grid_primary_2 {
    border: 2px solid var(--primary_color_shade4);  
}
.grid_primery_outline_primery {

    border: 2px solid var(--primary_color_shade6);
    padding: 2px 2px 2px 2px;
    margin: 3px 0px 3px 0px;
    text-align: center;
    font-style: normal;
    color:var(--white);
    background-color:var(--primary_color_shade4);

}

  .bg_white_outline_grey {
    border-radius: 5px;
    border: 2px solid var(--grey4);
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 40px;
    margin-top: 3px;
    font-style: normal;
    color:var(--grey5);
    background-color:var(--white);

}
.bg_green_outline_green {
    border-radius: 5px;
    border: 2px solid var(--green5);
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 40px;
    margin-top: 3px;
    font-style: normal;
    color:var(--white);
    background-color:var(--green3);

}
.bg_class_deselect {
    border-radius: 5px;
    border: 2px solid #fff;
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 40px;
    margin-top: 3px;
    font-style: normal;
    color: white;

}
.bg_class_deselect_grey{
    border-radius: 5px;
    border: 2px solid var(--grey5);
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 40px;
    margin-top: 3px;
    font-style: normal;
    color: var(--grey5);

}
.bg_class_deselect_grey2{
    border-radius: 5px;
    border: 2px solid var(--grey5);
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 80px;
    margin-top: 3px;
    font-style: normal;
    color: var(--grey5);

}
.gravity_center{
    display: flex;
    justify-content: center;
    align-items: center;
  
}
.gravity_center_vertical{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}
.gravity_center_horizontal{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
}
.gravity_center_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
  
}

.align_center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.content_center{
    margin-top:auto;
    margin-bottom:auto;
    text-align:center;

}
.gravity_center_right{
    display: flex;
    justify-content: flex-end;  
}
.bg_class_select {
    border-radius: 5px;
    border: 2px solid #0288D1;
    background-color: #FFF307;
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 40px;
    margin-top: 3px;
    font-style: normal;
    color: #FE488B;

}
.bg_class_select2 {
    border-radius: 5px;
    border: 2px solid #0288D1;
    background-color: #FFF307;
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 80px;
    margin-top: 3px;
    font-style: normal;
    color: #FE488B;

}
.bg_card_primary {
    border-radius: 10px;
    border: 2px solid #fff;
    background-color: var(--primary_color_shade5);
    
}
.bg_card_primary_outline {
    border-radius: 10px;
    border: 2px solid var(--primary_color_shade5);
    background-color: #FFF;
   
}
.bg_card_red {
    border-radius: 10px;
    border: 2px solid var(--red2);
    background-color: #ef5350;
    
}
.bg_card_red_outline {
    border-radius: 10px;
    border: 2px solid var(--card_red);
    background-color: #FFF;
   
}
.bg_card_orange {
    border-radius: 10px;
    border: 2px solid var(--deeporange2);
    background-color: var(--card_orange);
    
}
.bg_card_orange_outline {
    border-radius: 10px;
    border: 2px solid var(--card_orange);
    background-color: #FFF;
   
}
.bg_card_green {
    border-radius: 10px;
    border: 2px solid  var(--green1);
    background-color: var(--card_green);
    
}
.bg_card_green_outline {
    border-radius: 10px;
    border: 2px solid #89BE4C;
    background-color: #FFF;
   
}
.bg_card_deep_purple_outline {
    border-radius: 10px;
    border: 2px solid var(--card_deep_purple);
    background-color: #FFF;
   
}
.bg_card_deep_purple {
    border-radius: 10px;
    border: 2px solid var(--Deep_Purple2);
    background-color: var(--card_deep_purple);;
   
}
.bg_round_green {
    border-radius: 10px;
    background-color: #89BE4C;
    
}
.bg_card_grey {
    border-radius: 10px;
    border: 3px solid #fff;
    background-color: #BDBDBD;
    
}
.bg_card_grey_outline {
    border-radius: 10px;
    border: 2px solid #BDBDBD;
    background-color: #FFF;
   
}
.bg_round_grey {
    border-radius: 10px;
    background-color: #BDBDBD;
    
}
.bg_corner_round_grey {
    border-radius: 10px;
    background-color: var(--white);
    border: 1px solid var(--grey4);
    
}
.bg_corner_round_green {
    border-radius: 10px;
    background-color: var(--white);
    border: 1px solid var(--green4);
    
}
.bg_card_blue_outline{
    border-radius: 10px;
    border: 2px solid var(--card_blue);
    background-color: #FFF;   
   
}
.bg_card_blue {
    border-radius: 10px;
    border: 2px solid var(--light_blue1);;
    background-color: var(--card_blue);
    
}
.bg_card_blue_outline_nofill{
    border-radius: 10px;
    border: 3px solid var(--card_blue);
    
}
.bg_card_grey_tltr {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 2px solid #BDBDBD;
    background-color: #BDBDBD;
   
}
.bg_card_green_tltr {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: var(--card_green);
   
}
.bg_card_blue_tltr {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: var(--card_blue);
   
}
.bg_primery_t10_b30 {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: var(--primary_color_shade5);
    
}
.bg_white_t30_b10 {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: var(--white);
    
}
.bg_grey{
    background-color: #E0E0E0;
    
}
.bg_circle_primary_outline_yellow{
    background: var(--primary_color_shade5);
    border: 3px solid var(--highlight_color2);
    border-radius:100px;
}
.bg_rect_primary_outline_yellow{
    background: var(--primary_color_shade5);
    border: 3px solid var(--highlight_color2);
    border-radius:50px;
}
.bg_rect_grey_outline_primery{
    background: var(--grey2);
    border: 5px solid var(--primary_color_shade8);
    border-radius:20px;
}
.bg_circle_fill_primery_grey_outline{
    width: 120px;
    height: 120px;
    background: #29B6F6;
    border: 4px solid #E0E0E0;
    border-radius: 50%;
    
}

.bg_circle_fill_red_grey_outline{
    width: 120px;
    height: 120px;
    background: #ef5350;
    border: 4px solid #E0E0E0;
    border-radius: 50%;
}
.bg_circle_outline_primery{
    width: var(--mWidth);
    height: var(--mHeight);
    background: var(--white);
    border: var(--mBorder) solid var(--primary_color_shade5);
    border-radius: 50%;
}
.bg_circle_outline_grey{
    width: var(--mWidth);
    height: var(--mHeight);
    background: var(--white);
    border: var(--mBorder) solid var(--grey3);
    border-radius: 50%;
   
}
.bg_circle_primery{
    width: 120px;
    height: 120px;
    background: var(--primary_color_shade5);
    border: 3px solid var(--primary_color_shade2);
    border-radius: 50%;
    
}
.bg_circle_primery1{
    background: var(--primary_color_shade5);
    border: 1px solid var(--primary_color_shade2);
    border-radius: 50%;
    
}

.bg_circle_card_orange{
    width: 120px;
    height: 120px;
    background: var(--card_orange);
    border: 3px solid var(--deeporange3);
    border-radius: 50%;
}
.bg_circle_amber{
    width: 120px;
    height: 120px;
    background: var(--amber4);
    border: 3px solid var(--amber2);
    border-radius: 50%;
}
.bg_circle_card_red{
    width: 120px;
    height: 120px;
    background: var(--card_red);;
    border: 3px solid var(--red2);
    border-radius: 50%;
}
.bg_page_smokewhite_rounded{
    background: var(--whitesmoke);
    padding: 10px 10px 20px 10px;
    margin: 0px var(--mMargin) 0px var(--mMargin);
    border-radius: 10px;
}

.bg_page_white_rounded{
    background: #fff;
    padding: 10px 10px 20px 10px;
    margin: 0px var(--mMargin) 0px var(--mMargin);
    border-radius: 10px;
}
.bg_page_grey_rounded{
    background: var(--grey3);
    padding: 10px 10px 20px 10px;
    margin: 0px var(--mMargin) 0px var(--mMargin);
    border-radius: 10px;
}
.bg_page_greylite_rounded{
    background: var(--grey1);
    padding: 10px 10px 20px 10px;
    margin: 0px var(--mMargin) 0px var(--mMargin);
    border-radius: 10px;
}
.bg_page_primary_rounded{
    background: var(--primary_color_shade0);
    padding: 10px 10px 20px 10px;
    margin: 0px var(--mMargin) 0px var(--mMargin);
    border-radius: 10px;
}
.bg_smokewhite_blbr{
    background: whitesmoke;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
   
}
.bg_smokewhite_tltr{
    background: whitesmoke;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
   
}
.bg_smokewhite{
    background: whitesmoke;
    border: 3px solid var(--grey4);
    border-radius: 8px;
   
}
.circular_image{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid var(--primary_color_shade1);
    overflow: hidden;
    display:inline-block;
    vertical-align:middle;
    background: var(--primary_color_shade2);
    overflow:hidden;

}
.circular_image_student_details{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid var(--primary_color_shade1);
    overflow: hidden;
    display:inline-block;
    vertical-align:middle;
    background: var(--primary_color_shade2);
    overflow:hidden;

}
.circular_image_student_details img{
    width:100%;
  }


.rect_image_view{
    width: 80px;
    height:100px;
    border: 2px solid var(--grey3);
    overflow: hidden;
    display:inline-block;
    vertical-align:middle;
    background: var(--whitesmoke);
    overflow:hidden;
}
.rect_image_view img{
    width: 100%;
    height:100%;
   
}
.circular_image_primery{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid var(--primary_color_shade1);
    background: var(--primary_color_shade4);
    overflow:hidden;

}
.circular_image_custom{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: var(--myBorder) solid var(--myBorderColor);
    overflow: hidden;
    background: var(--myFillColor);
   
}

  .circular_image img{
    width:100%;
  }
 
.card{

    background-color: #f1f1f1;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;

}
.viewHide{
    display: none;
}
.Hidden{
    display: none;
}
.viewShow{
    display: block;
}
.text-center{
    text-align: center;
    margin: auto;
    display:inline-block;
    position:relative;

}
.box_shadow{   
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
}

.box_shadow_primary{   
    box-shadow: 0 2px 4px 0 var(--primary_color_shade2),0 2px 10px 0 var(--primary_color_shade2)!important;
}

.box_shadow_highlight1{   
    box-shadow: 0 2px 4px 0 var(--highlight_color1),0 2px 10px 0 var(--highlight_color1)!important;
}

.box_shadow_highlight2{   
    box-shadow: 0 2px 4px 0 var(--highlight_color2),0 2px 10px 0 var(--highlight_color2)!important;
}

.bg_circle_green_check{
    background: var(--green3);
    width: 50px;
    height: 50px;
    font-weight: 900;
    padding: 2px;
    border-radius: 50%;
    color: var(--white);
    margin: 0 auto;
    font-size: 28px;
    text-align: center;
}
.bg_circle_red_check{
    background: var(--red4);
    width: 50px;
    height: 50px;
    font-weight: 900;
    padding: 2px;
    border-radius: 50%;
    color: var(--white);
    margin: 0 auto;
    font-size: 28px;
    text-align: center;
}
.bg_circle_primery_check{
    background: var(--primary_color_shade3);
    width: 50px;
    height: 50px;
    font-weight: 900;
    padding: 2px;
    border-radius: 50%;
    color: var(--white);
    margin: 0 auto;
    font-size: 28px;
    text-align: center;
}
.nowrap {
    white-space: nowrap ;
  }
.edt_address{
        height: 60px;
        width: 100%;
        font-size: 18px;
        padding: 3px;
        margin-top: 5px;
        margin-bottom: 5px;
}

.check_box1{
    transform: scale(1.5);
   
}
.img_sample_docs{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid var(--primary_color_shade1);
    overflow: hidden;
    display:inline-block;
    vertical-align:middle;
    background: var(--primary_color_shade2);
    overflow:hidden;
}
.img_sample_docs img{
    width:100%;
    height: 100%;
}