*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form{
  padding:5px;
}

.borderShad
{
  margin-top: 50px;
background-color: #f1f1f1;
box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
}

.form-control {
  width: 90%;
  margin-left: 5%;

}


div.relative {
  position: relative;
  width:450px;
  height:200px;
  
} 

div.absolute {
  position: absolute;
  top: 30%;
  margin: auto;
  left:35%;
  padding: 45px 20px;
  width:450px;
  background-color: #000000;
  border: 3px solid #fff;
  color: #fff;
  border-radius: 5px;
  opacity:0.8;
}

ul#main-nav > li {
  border-bottom: 1px solid #fff;
  width: 100%;
}
