.dropdown-container-flex {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the heading and dropdown */
  width: 300px;
  margin: 2px auto;
  font-family: Arial, sans-serif;
}
.styled-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  appearance: none; /* Remove default arrow for better control */
}

.styled-select:hover {
  border-color: #007bff;
}

.styled-select:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
h3 {
  margin: 0;
  font-size: 16px;
  white-space: nowrap; /* Prevent wrapping for the heading */
}
