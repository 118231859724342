*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form{
  padding:5px;
}

.borderShad
{
  margin-top: 50px;
background-color: #f1f1f1;
box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
}

.form-control {
  width: 90%;
  margin-left: 5%;

}


div.relative {
  position: relative;
  width:450px;
  height:200px;
  
} 

div.absolute {
  position: absolute;
  top: 30%;
  margin: auto;
  left:35%;
  padding: 45px 20px;
  width:450px;
  background-color: #000000;
  border: 3px solid #fff;
  color: #fff;
  border-radius: 5px;
  opacity:0.8;
}

ul#main-nav > li {
  border-bottom: 1px solid #fff;
  width: 100%;
}

/********************************************************************************************/

.toggle-container {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.id_card_settings_options {
  color: black;
  text-align: center;
  font-size: large;
  font-weight: bolder;
  font-family: "Courier New", Courier, monospace;
}

.fee_collection_month {
  position: absolute;
  padding: 1px 8px 1px 8px;
  top: 46px;
}
.div_addfeebox {
  height: auto;
  width: 50vw;
  border: 8px solid var(--card_blue);
  border-radius: 20px;
  background-color: whitesmoke;
}
.div_addfeebox .inputBox {
  position: relative;
}
.div_addfeebox .inputBox input {
  width: 100%;
  padding: 8px;
  font-size: 18px;
}
.div_addfeebox .select-field option,
select {
  font-size: 16px;
}
.div_addfeebox h2 {
  background-color: var(--primary_color_shade1);
  width: 200px;
  height: auto;
  margin-top: 5px;
  border: 3px solid var(--primary_color_shade2);
  border-radius: 10px;
}
.select-field {
  width: 100%;
  padding: 5px;
  border-radius: 8px;
  margin: 2px 2px;
}
.student_id {
  position: absolute;
  width: 100%;
  background: #6de8a394;
  padding: 0px 5px;
  color: var(--black);
  font-size: 16px;
  font-weight: bold;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.h5-font-mt {
  margin-top: 2px;
  color: white;
}

.left-arrow {
  transform: rotate(-180deg);
  float: left;
  border-radius: 50%;
  border: 1px solid #28a745;
  color: #28a745;
  padding: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.right-arrow {
  float: right;
  border-radius: 50%;
  border: 1px solid #28a745;
  color: #28a745;
  padding: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.class-list-box-privelege {
  background-color: #f1f1f1;
  /* padding: 1.01em 12px; */
  margin: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.div-module-clerk {
  background-color: var(--white);
  height: 150px;
  margin: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.bg-circle-clerk-module {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid var(--highlight_color2);
  padding: 10px;
  margin-top: 10px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  background: var(--primary_color_shade4);
}
.bg-circle-clerk-module img {
  width: 100%;
}
.bg-module-admin {
  width: 100%;
  height: 100%;
  background: var(--indian_saffron5);
  padding: 5px;
  margin-top: 10px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border: 3px solid var(--highlight_color4);
}
.bg-module-admin img {
  width: 100%;
}
.img-checked {
  width: 200px;
  height: 220px;
  margin: 0 auto;
}
.img-checked img {
  width: 100%;
}

.outstanding-bg {
  background: var(--primary_color_shade5);
  padding: 8px;
  margin: 0px 5px;
  border-radius: 3px;
}

/***********************************************************************************************/

.v-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vv {
  position: relative;
}

.paper-d {
  border: 1px solid #cdddea;
}
.chek {
  width: 40px;
  height: 40px;
  background: #88b93c;
  border-radius: 50%;
  padding: 10px 15px;
}
.chek-in {
  transform: scale(2);
}
.paper-d > tr td {
  padding: 5px 10px;
  font-size: 17px;
}
.po button {
  border: dodgerblue;
}
.text-bott {
  background: #fea223;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
}
.rr {
  transform: scale(2);
  border-radius: 50%;
}
.bor-exam-l {
  border: 1px solid #000;
  padding: 3px 25px;
  border-radius: 20px;
  font-size: 16px;
  margin: 5px;
}
.pk tr td {
  padding: 2px 10px;
  font-size: 15px;
}
.f-18 {
  font-size: 19px;
}
.f-16 {
  font-size: 17px;
}
.adm-det p {
  font-size: 17px;
  margin: 8px;
}

.upd {
  border: double #007bff;
  border-radius: 24px;
  padding: 4px 10px;
  font-size: 15px;
  margin: 5px 0px;
}

.pk tr td p {
  margin: 3px;
}

.inne-section h4 > span {
  font-weight: 300;
  font-size: 20px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.resizef {
  font-size: 20px;
}

.inne-section-1 {
  color: rgb(10, 8, 8);
  text-align: center;
  padding-top: 10px;
}

.inne-section-1 img {
  width: 60%;
  background: #454c76;
  padding: 10px;
  font-size: 40px;
  border: 3px solid #ffc107;
  /* border-radius: 50%; */
}
.inne-section-1 h4 {
  position: relative;
  height: 48px;
}

.inne-section-1 h4 > span {
  font-weight: 300;
  font-size: 20px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.row-30-min-bg {
  margin: 0px -30px;
  background: #454c76;
  color: #fff;
}
.row-30-min-bg-red {
  background: rgb(79, 195, 247);
  /* background: #ff5722; */
  color: #fff;
}

/* add css for class list 15-09-2019 */

.card-class {
  background: #454c76;
  color: #fff;
  padding-top: 2em;
}

.class-list-box {
  background-color: #f1f1f1;
  padding: 1.01em 12px;
  margin: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.card-class h4,
.card-class h2 {
  text-align: center;
}

.mar-t {
  /* margin: -30px -30px 0px; */
  background: #7a81a9;
}
.class-li a {
  text-decoration: none;
  color: black;
}

.class-li .col-sm-7 p {
  margin-bottom: 0.5px;
}
.fee-collection {
  background: #915906;
  border: none;
  color: #fff;
  padding: 16px 94px;
  font-size: 20px;
  position: absolute;
  left: 35%;
  top: 31.5%;
}

.p-0-m-20 {
  padding: 0px;
  margin: 20px 0px;
}
.fee-d-details {
  width: 150px;
  height: 150px;
  margin: 10px auto;
  border: double #007bff;
  padding: 20px;
  color: #007bff;
}
.fee-button {
  font-size: 20px;
  padding: 5px 60px;
}
.p-0 {
  padding: 0px;
}
.f-45 {
  font-size: 45px;
}
.f-24 {
  font-size: 24px;
}

.input-customize {
  padding: 10px;
  border: 1px solid #b1adad;
}
.pd-20 {
  padding: 20px;
}
.arrow:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  vertical-align: middle;
}
.arrow {
  cursor: pointer;
}
.stu-info-roll-circle {
  border: 3px solid #fff;
  border-radius: 50%;
  width: 125px;
  height: 125px;
  margin: 0 auto;
  padding: 4px;
}
.en-adhar {
  color: #ff9800;
  font-family: serif;
}
.arrow:after {
  border-left-color: #fefefe;
  margin: 0px 10px;
}
.search-text {
  padding: 11px;
  background: #464d76;
  color: #fff;
}
.p-b-margin p {
  margin-top: 0;
  margin-bottom: 0rem;
  font-size: 11px;
}
.h5-font-siz {
  font-size: 12px;
}
.a-decoration-none,
.a-decoration-none:hover {
  color: #fff;
  text-decoration: none;
}
.stu-info-main-bg {
  background: #123abcbf;
}
.st-list-roll-no {
  background: #fff;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  margin: 0 auto;
  font-size: 20px;
}
.p-b-mar {
  padding: 0px 15px;
}
.p-b-mar p {
  font-size: 15px;
  margin-bottom: 0px;
}
.stu-info-main-bg-1 {
  background: rgba(18, 58, 188, 0.75);
  padding: 5px;
}

.stu-info-main-bg-1 p {
  color: #fff;
  font-size: 15px;
}
.th-td-p th,
.th-td-p td {
  padding: 5px;
  border: 1px solid #dbd7d7;
}
.th-td-p tr:nth-child(odd) {
  background: #f7f7f7;
}
.pt-45 {
  padding-top: 15px;
}
.collect-button-1 {
  background: #7a81a9;
  margin: 0px -30px;
}
.stu-info-pic {
  width: 70%;
  padding: 5px;
  border: 3px solid #fff;
  margin: 3% 14%;
}
.stu-info-pic img {
  width: 100%;
  height: 150px;
}
.or-cust {
  border: 1px solid #fff;
  width: 50px;
  height: 50px;
  font-size: 15px;
  padding: 15px 8px;
  background: #7a81a9;
  border-radius: 50%;
  color: #fff;
  margin: -5px auto;
}

.stu-info-detail {
  padding: 4px 10px;
  background: #007bff;
  color: #fff;
  text-align: center;
}

/* Add css for payment history  */
.payment-history-sec {
  /* box-shadow: 0 2px 4px 0 rgba(0,0,0,1),0 2px 10px 0 rgba(0,0,0,0.89)!important; */
  margin: 0 auto;
  padding: 25px;
  background: #464d76;
}
.payment-hist-6-sec {
  background: #fff;
  padding: 25px;
}
.payment-hist-student-details {
  padding: 25px;
  border: 1px dotted #fff;
  margin: 10px 20px;
}

.pament-hist-text p {
  color: #fff;
  font-size: 15px;
  padding-top: 10px;
  margin: 0px;
}
.pament-hist-text a {
  text-decoration: none;
}
.payment-details {
  background: #fff;
  padding: 36px;
  border-right: 1px solid #afaaaa;
  margin-bottom: 5px;
}
.payment-details p {
  color: #404040;
  margin-bottom: 0px;
}
.payment-details-check {
  margin-bottom: 5px;
  border-right: 1px solid #afaaaa;
}
.input-group {
  margin-bottom: 10px;
}
.heading-with-bg-w {
  background: #0277bd;
  padding: 2px;
  margin: 10px 0px;
  color: #fff;
  clear: both;
  border-radius: 5px;
}
.col-md-10 .form-control {
  margin-left: 2%;
}
.admin-menu-img-size-1 {
  width: 15%;
  margin: 0px 10px;
  /* background: #464d76; */
  padding: 2px;
  border: 3px solid #fff;
}
.admission-dashboard-img {
  width: 65%;
  margin: 0 15%;
}
.card-class-w img {
  width: 100%;
  height: 100%;
  border: 1px solid #454c76;
  padding: 5px;
}

/* add css for student details */
.content-customize-area-student-details {
  padding: 30px 0px;
  margin-top: 0px !important;
}
.p-40 {
  padding: 40px;
}
.img-phto {
  width: 60%;
  height: 155px;
  margin: 10px;
}
.border-l-r {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  text-align: center;
}
.photo-row-bottom {
  padding: 5px;
  color: #2d2c2c;
  border-top: 1px dotted #454c76;
  border-bottom: 1px dotted #454c76;
}
.stu-details table {
  width: 100%;
}
.stu-details table tr {
  border: 1px solid #2d2c2c;
}
.stu-details table td {
  padding: 10px;
}
.stu-details {
  padding: 0px 38px;
}
.contact-details .col-sm-4,
.contact-details .col-sm-8 {
  border-right: 1px solid #2d2c2c;
}
.family-p {
  font-size: 18px;
  margin: 10px;
  font-weight: 500;
}
.family-p-bottom {
  font-size: 18px;
  margin: 10px;
  font-weight: 400;
}
.family-details-poto img {
  width: 100%;
}
.family-details-poto {
  width: 175px;
  height: 175px;
  border: 5px solid #454c76;
  padding: 4px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
}
.contact-details-row {
  background: #454c76;
  color: #fff;
  margin: 20px -20px;
}
.class-list-box-c {
  /* background-color: #f1f1f1; */
  padding: 1.01em 12px;
  margin: 5px;
  /* box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important; */
  border: 1px solid #d6c2c2;
}
.current-address-row {
  background: #67a28b;
  color: #fff;
  margin: 20px;
}
.row-p-20 {
  padding: 20px;
}

/* Notce board css */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.add-notice-text-p {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
}
.s-change {
  margin: 0 auto;
  width: 28%;
  padding: 8px;
  font-size: 17px;
  border-radius: 30px;
}
.s-pad-30 {
  padding: 30px 15px;
}
.notice-order {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
}
.heading-with-bg-w-notice {
  background: #464d76;
  padding: 5px;
  margin: 0px 0px 45px;
  color: #fff;
  clear: both;
}
.ch-in {
  transform: scale(2);
}
.ch-text-s {
  font-size: 16px;
}
.ment {
  background: azure;
  padding: 15px;
  width: 100%;
}
.b-color {
  background: rgba(52, 67, 116, 0.95);
  color: rgb(255, 255, 255);
  width: 100%;
  padding: 15px;
  font-size: 20px;
}
.b-color-w {
  background: rgba(255, 255, 255, 0.95);
  color: rgb(116, 72, 124);
  width: 100%;
  padding: 15px;
  font-size: 20px;
}
/* add css for selectmultiple students */

.selectMultiplst-h4 {
  background: #c8c2c2;
  padding: 10px;
  font-size: 16px;
}
.selectmuliple-class-check {
  transform: scale(2);
}

/* Add css for Attendance */

.attend {
  padding: 10px;
  width: 60px;
  height: 60px;
  background: antiquewhite;
  border: double;
  color: black;
  margin: 5px auto;
  font-size: 24px;
}
.card-class-att {
  background: #454c76;
  color: #fff;
  padding-top: 5px;
}
.attend-c {
  padding: 10px;
  width: 60px;
  height: 60px;
  background: antiquewhite;
  border-radius: 50%;
  color: black;
  margin: 5px auto;
  font-size: 24px;
}
.active-attendance {
  display: none;
}
.attend-status {
  background: #fff;
  padding: 14px;
}
.atten-1 {
  padding: 10px 4px;
}
.atten-2 {
  border-right: 1px solid #c0bbbb;
  font-weight: 600;
}
.atten-3 {
  padding: 0px;
  border-radius: 10px;
  cursor: pointer;
  background: #454c76;
  color: #fff;
}
.butt-p1 {
  width: 30px;
  height: 30px;
  background: black;
  border-radius: 50%;
  padding: 4px 13px;
  color: #000;
  margin: 10px;
}
.butt-p2 {
  width: 30px;
  height: 30px;
  background: black;
  border-radius: 50%;
  padding: 4px 13px;
  color: #000;
  margin: 10px;
}
.butt-p3 {
  width: 30px;
  height: 30px;
  background: black;
  border-radius: 50%;
  padding: 4px 13px;
  color: #000;
  margin: 10px;
}
.butt-p4 {
  width: 30px;
  height: 30px;
  background: black;
  border-radius: 50%;
  padding: 4px 13px;
  color: #000;
  margin: 10px;
}
.bb-switch {
  position: absolute;
  right: 60px;
}
.attend-button {
  float: right;
  border-radius: 21px;
  font-size: 14px;
  padding: 6px 20px;
}
.top-bottom-center {
  display: table-cell;
  height: 105px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}
.top-bottom-center-present {
  transform: scale(1.7);
  border-radius: 50%;
  background: #2f9f48;
  width: 30px;
  padding: 5px;
  color: #fff;
  height: 30px;
}
.top-bottom-center-sr {
  transform: scale(1.2);
  border-radius: 50%;
  background: #a5b0a7;
  width: 30px;
  padding: 0px;
  color: #fff;
  height: 30px;
}
.after-attendance-sec {
  background: aliceblue;
  padding: 20px 10px;
}
.tbl td:nth-child(even) {
  background-color: #d4e9ff;
  text-align: center;
  padding: 10px;
  font-weight: 500;
}
.tbl td:nth-child(odd) {
  background-color: #ecfad6;
  text-align: center;
  padding: 10px;
  font-weight: 500;
}
.tbl td button {
  padding: 5px;
  width: 30%;
  border-radius: 10px;
  font-weight: 700;
}
.hideen-taken-attendance {
  display: none;
}
.posi-rel {
  position: relative;
  border: 1px solid #dddcdc;
  border-radius: 5px;
}
.bg-reg-ad-no {
  background: #474e7666;
  color: #000;
  margin: 50px 0px;
  padding: 10px;
}
.bg-st-img-left {
  background: #ececec;
  padding-top: 20px;
}
.ad-radio {
  transform: scale(2.5);
  margin-left: -10px !important;
}
.freg {
  font-weight: 700 !important;
  font-size: 16px;
}

.up-img {
  position: absolute;
  font-size: 12px;
  right: 0px;
  top: 4px;
  border-radius: 20px;
  background: #efeeeb;
  padding: 4px;
  border: 1px solid #868282;
  cursor: pointer;
}
.button-circlea {
  border: 2px solid #337ab7;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  font-size: 40px;
  color: #7a81a9;
  margin: 10px auto;
}
.button-circlep {
  border: 2px solid #28a745;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  font-size: 40px;
  color: #28a745;
  margin: 10px auto;
}
.present-status {
  background: #28a745;
  padding: 10px;
  font-weight: 500;
  position: relative;
}
.prsent-status {
  width: 40px;
  height: 40px;
  background: antiquewhite;
  padding: 10px 18px;
  border-radius: 50%;
  font-size: 21px;
}
.white-bg-button {
  background: #fff;
  padding-top: 20px;
}
.student-attend-bottom {
  background: #f7ffff;
  margin: -5px 5px;
  padding: 5px;
}
.student-attend-bottom button {
  border-radius: 20px;
  padding: 8px 25px;
}
.update-exam-status {
  border-radius: 5px;
  padding: 8px;
  border: double #7a81a9;
  color: #6f746f;
}
.examList-bg {
  background-color: #f1f1f1;
  padding: 1.01em 12px;
  margin: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.bord-l-r {
  border-left: 1px solid #dbd7d7;
  border-right: 1px solid #dbd7d7;
}
.bg-student-prsent {
  padding: 20px 0px;
  background: aqua;
}
.adt-roll {
  width: 45px;
  height: 45px;
  padding: 8px;
  background: #28a745;
  border-radius: 50%;
  font-size: 15px;
  margin: 5px auto;
  color: #fff;
}
.b-bg-c-bg {
  padding: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  margin-bottom: 20px;
}
.button-circleli {
  border: 2px solid #88b93c;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  font-size: 40px;
  color: #88b93c;
  margin: 10px auto;
}
.button-circleall {
  border: 2px solid #464d76;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  font-size: 40px;
  color: #464d76;
  margin: 10px auto;
}

/* add css exam paper setting */
.exam-p-setting-cls {
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 10px 2px;
  text-align: center;
  height: 50px;
  margin-top: 3px;
}
.exam-p-setting-sub {
  border-radius: 4px;
  border: 1px solid #fff;
  background: #b1adad;
  padding: 5px;
  text-align: center;
  height: 35px;
  color: #fff;
}
.exam-section-1 {
  background: #77b9f1;
  padding: 5px;
}
.exam-section-2 {
  padding: 25px 0px;
}
.exam-section-3 {
  padding: 10px;
  background: aliceblue;
}
.exam-section-4 {
  padding: 10px;
  background: #f0f0f0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.fee-dash-b {
  padding: 10px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

/* Add css for myprofile  */

.myprop {
  margin: 40px auto;
}
.photo-st {
  width: 130px;
  margin: 13px auto;
  height: 130px;
}
.sect-bg-clean-qla {
  box-shadow: 1px 0px 8px #00000026;
  border: 1px solid #eee;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 2px 0px;
}

.myprop img {
  border-radius: 50%;
}
.holiday-tb th,
.holiday-tb td {
  padding: 15px;
}
.holiday-tb tr:nth-child(even) {
  background: #dbd7d7;
  color: #337ab7;
}
.color-a {
  color: #337ab7;
}
.school-update {
  background: azure;
  padding: 45px 0px !important;
  border-radius: 20px;
  border: double #28a745;
}
.reply-comment {
  border-radius: 43px;
  border: double #337ab7;
  margin: 0px 0px;
  width: 12%;
  font-size: 14px;
}
.replayer {
  width: 30%;
}
.pendding {
  width: 50px;
  height: 50px;
  background: burlywood;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
  padding: 3px;
  border-radius: 50%;
}
.bor-r-leave {
  border-right: 1px solid #e3e3e3;
}
.class-white {
  border: 1px solid #fff;
}
.bor-t-leave {
  border-top: 1px solid #e3e3e3;
}
.bor-b-leave {
  border-bottom: 1px solid #e3e3e3;
  padding: 10px 0px;
}
.text-medium {
  font-size: 16px;
}
.pendding-top-row {
  background: #28a745;
  padding: 10px;
  font-size: 15px;
}
.cur {
  cursor: pointer;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 20px;
  text-transform: uppercase;
}
.sect-bg-clean {
  box-shadow: 1px 3px 8px #00000026;
  border: 1px solid #eee;
  padding: 5px 10px;
  border-radius: 20px;
}
.heading-with-bg-w {
  font-size: 22px;
}
.border-bott {
  border-bottom: 1px dotted #a3a1a1;
  padding: 10px;
}

.w-100 {
  width: 100%;
}
.f-w-300 {
  font-weight: 300;
}
.staff-profile-detail {
  border: 1px solid #fff;
  padding: 10px;
}
.form-com {
  width: 100%;
  height: 130px;
  border-radius: 10px;
}
.success-m {
  margin: 10px auto;
  background: #fff;
  border-radius: 10px;
  padding: 55px;
  height: 600px;
}
.s-mt-100 {
  margin-top: 100px;
}

.sp-10 {
  padding: 10px;
}
.im img {
  width: 100%;
}
.drop-h {
  background: #007bff3d;
  padding: 8px;
  color: #fff;
  font-size: 18px;
}

.form-ii {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  border: 1px solid #cecccc !important;
}
.class-set {
  background: #505a95;
  color: #fff;
  padding: 5px 22px;
}
.add-cls-sec {
  border-radius: 19px;
  background: #fff;
  color: crimson;
  font-size: 16px;
  padding: 4px 15px;
  margin-top: 6px;
  font-weight: 400;
  border: double #a5a1a1;
}
.divid-sec {
  background-color: #f1f1f1;
  padding: 1.01em 28px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  margin: 3px;
}
.dt-text-p {
  padding: 10px;
  border: 1px solid #000;
  margin: 10px;
}
.htr {
  background-color: #f1f1f1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.mt-10 {
  margin-top: 10px;
}
.fee-month {
  width: 100px;
  margin: 3px auto;
  height: 100px;
  border: 2px solid #337ab7;
  text-align: center;
  border-radius: 50%;
  padding: 15px;
  font-weight: 700;
}
.fee-m-session {
  background: #88b93c;
  border-radius: 4px;
  color: #fff;
  position: absolute;
  padding: 3px 20px;
  top: 80px;
  margin: 0px 0px 0px -12px;
}

/* add css for fee dashboard */
.fee-d-date-s {
  background: #e8f1ee;
  padding: 10px;
  text-align: center;
}
.fee-d-button-bg-s {
  background: #aad0f8;
  padding: 10px;
}
.border-left-primary {
  border-left: 0.25rem solid #4e73df !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}
.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}
.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}
.border-left-warning {
  border-left: 0.25rem solid #f6c23e !important;
}
.col-xs-d10,
.col-sm-d10,
.col-md-d10,
.col-lg-d10 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 11.11%;
  max-width: 11.11%;
}

.bg-sdk {
  background: #c5ffd2;
  padding: 10px 0px 0px;
}
.p-10 {
  padding: 10px;
}
.lin .btn {
  margin-bottom: 0px;
}
.lin a:hover {
  background-color: #007bff !important;
}
.clf {
  border: double;
  padding: 5px 10px;
}
.cld {
  border: double;
  padding: 0px 10px;
  border-radius: 20px;
}
.otherfeesetting-b {
  border-radius: 20px;
  padding: 5px 44px;
  font-size: 21px;
  float: right;
}
.ft-p {
  background: #fff;
  padding: 20px;
  text-align: center;
  font-size: 18px;
  height: 120px;
  border: double rgba(128, 149, 215, 0.75);
}
.w-h-size {
  width: 150px;
  height: 150px;
}
.ad-tab {
  width: 100%;
  margin: 20px 0px;
  text-align: center;
}
.heit {
  height: 160px;
}
.w-h-size-1 {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
.border-1 {
  border: 2px solid #000;
}
.adm-det {
  border: 1px solid #000;
  margin: 10px -13px;
}
.adm-det-Le {
  border-left: 1px solid #000;
}
.adm-det-Ri {
  border-right: 1px solid #000;
}
#divehd {
  display: none;
}
.ht {
  width: 75%;
  margin: 0 auto;
}
.vvb {
  background: url(/static/media/EduOk.33c109dc.png);
  background-repeat: no-repeat;
  background-position: center;
}
.bg-white-11 {
  background: #fffffff0;
}
.border-1b {
  border: 1px solid #000;
  padding: 5px;
}
.margin-2 {
  margin: 2px;
}
.mmr-bt {
  border-top: 1px solid #000;
  margin: 0px -12px;
  padding-top: 5px;
}
.f-cursive {
  font-family: cursive;
}
.tt-t {
  text-align: justify;
  padding: 5px;
}
.pdl-5 {
  padding-left: 5px;
}
.mr-10 {
  margin: 10px;
}
.height-58 {
  height: 58px;
}
.ft-op {
  font-size: 23px !important;
}
.tbl-wh {
  width: 100%;
  height: 300px;
}
.mmrk p,
.mmrk h4,
.mmrk h2,
.mmrk h3,
.mmrk th,
.mmrk td {
  font-family: serif;
}

.f-600 {
  font-weight: 600;
}
.ht-100 {
  height: 100px;
}
.mmrk-student-details {
  word-spacing: 5px;
  letter-spacing: 3px;
  font-size: 25px;
  font-weight: 600;
  margin-left: 275px;
}

.name-st {
  font-size: 23px !important;
}
.D1 {
  font-size: 21px !important;
  color: #4d4d4d;
}
.w-h-size-mark {
  width: 150px;
  height: 178px;
}
.f-25 {
  font-size: 25px;
}
.f-f {
  font-family: sans-serif;
}

.f-21 {
  font-size: 21px;
}
.h-40 {
  height: 40px;
  color: #4d4d4d;
}
.bor-b {
  border-bottom: 1px solid #000;
}
.bor-t {
  border-top: 1px solid #000;
}
.bor-t2 {
  border-top: 2px solid #000;
}
.bor-rr {
  border-right: 2px solid #000;
}
.tbb > tr th:nth-child(1),
.tbb > tr td:nth-child(1),
.tbb > tr th:nth-child(2),
.tbb > tr td:nth-child(2),
.tbb > tr th:nth-child(3),
.tbb > tr td:nth-child(8),
.tbb > tr th:nth-child(4),
.tbb > tr td:nth-child(14) {
  border-right: 2px solid #000;
}
/* Add new css 28-06-2020 */

.bg-yellow {
  background-color: yellow;
}
.du-old {
  padding: 10px;
  background: #d33636;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
.profil-im {
  border-radius: 10px;
  border: 2px solid #ffff;
  padding: 10px;
  background: #d33636;
}

.profil-im img {
  width: 100%;
}

.profil-d {
  border-radius: 10px;
  border: 2px solid #d33636;
}
.profil-d > p {
  margin-bottom: 0px !important;
}

.weight-d7 {
  color: #f11919;
  font-weight: 700;
}
.darkkhaki {
  color: darkkhaki;
}
.rld {
  padding: 0px 30px 0px 15px;
}
.rld a {
  text-decoration: none;
}

.fee-receipt-pr tr td {
  padding: 6px 10px;
}
.fee-receipt-pr-1 tr td,
.fee-receipt-pr-1 tr th {
  padding: 6px 10px;
}
.th-section {
  width: 120px;
  height: 120px;
  background: #ba7ff1;
  border: 2px solid #bfe3c7;
  border-radius: 50%;
  text-align: center;
  padding: 32px 24px;
  font-size: 18px;
}
.th-section a {
  color: #fff;
}
.mb-100 {
  margin-bottom: 100px;
}

.s-bt {
  position: absolute;
  top: 27px;
  left: 15px;
}

.right-prive-box {
  background: #007bff;
  padding: 10px;
}
.switch-height-20 {
  height: 20px;
}
.switch-height-20 > .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}
/* Add new css for fee card 26-07-2020 */
.fee-card-tabe th,
.fee-card-tabe td {
  padding: 5px 10px;
}
/* Add new css for fee card 01-08-2020 */
.staff-img-path {
  width: 35%;
  border: 1px solid rgba(18, 58, 188, 0.75);
  border-radius: 50%;
  padding: 3px;
}
.left-arrow-light {
  transform: rotate(-180deg);
  float: left;
  border-radius: 50%;
  border: 1px solid #28a7451f;
  color: #28a74526;
  padding: 5px;
  width: 40px;
  height: 40px;
}
.right-arrow-light {
  float: right;
  border-radius: 50%;
  border: 1px solid #28a7451f;
  color: #28a74526;
  padding: 5px;
  width: 40px;
  height: 40px;
}

/* add css for calender in home work componet 04-08-2020 */
ul {
  list-style-type: none;
}
.month {
  padding: 10px 25px;
  width: 100%;
  background: rgb(3, 169, 244);
  text-align: center;
}

.month ul {
  margin: 0;
  padding: 0;
}

.month ul li {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.month .prev {
  float: left;
  padding-top: 10px;
}

.month .next {
  float: right;
  padding-top: 10px;
}

.weekdays {
  margin: 0;
  padding: 10px 0;
  background-color: #ddd;
}

.weekdays li {
  display: inline-block;
  width: 13.6%;
  color: #666;
  text-align: center;
}

.days {
  padding: 10px 0;
  background: #eee;
  margin: 0;
}

.days li {
  list-style-type: none;
  display: inline-block;
  width: 13.6%;
  text-align: center;
  margin-bottom: 5px;
  font-size: 12px;
  color: #777;
}

.days li .active {
  padding: 5px;
  background: #1abc9c;
  color: white !important;
}

/* Add css for shift batch 07-08-2020 */
.rld-28 {
  /* padding: 0px 0px 0px 28px; */
  margin-top: 3px;
}
.rld-inner-row {
  border: 1px solid #178530;
  padding: 2px;
  border-radius: 10px;
  margin: 0px;
}

.cl-3-part {
  background: #329247;
  border-radius: 10px;
  margin: 0px;
}
.profil-im-w {
  border-left: 2px solid #ececec;
}
.profil-im-img {
  border-radius: 50%;
  border: 2px solid #ffff;
  padding: 18px;
  background: #4fc3f7;
}
.profil-im-img img {
  width: 100%;
}
.gh-b {
  border: 2px solid #ff6927;
  border-radius: 10px;
}
.stf-bg {
  position: absolute;
  top: 84%;
  width: 100%;
  left: 0%;
  background: #6de8a394;
  padding: 0px 15px;
  color: #fff;
}
.p-checkbox {
  margin: 10px;
  transform: scale(1.5);
}
.upload-icon {
  width: 90%;
  transform: rotate(90deg);
  margin-top: -8px;
  margin-right: -33px;
  cursor: pointer;
}
.selected-stu-1 {
  background: #b8b0b0;
  padding: 10px;
}
.inactive-field {
  display: none;
}
.active-field {
  display: block;
}

/* add css for modal in batch shifting  */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
/* add css for students component 18-08-2020 */
.bb-r-c {
  border: 1px solid #007bff;
  border-radius: 10px;
}
.height-130 {
  height: 110px;
}

/* Add css for student Details // 03-09-2020 */
.m-0-100 {
  padding: 20px 100px;
  background: #fff5f5;
  margin: 80px 100px;
  border-radius: 30px;
}
.photo-st-21 {
  width: 200px;
  margin: 12px auto;
  height: 200px;
  border: 10px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}
.cam {
  position: absolute;
  width: 35%;
  top: 120px;
  padding: 5px;
  left: 145px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
}
.basic-de {
  background-color: #007bff80;
}
/* Add css for Fee collection Details // 17-09-2020  */
.clHide {
  display: none;
}
.clShow {
  display: block;
}
.oldduelist-img-border {
  width: 100%;
  background: #eee;
  border: 4px solid #fff;
  border-radius: 50%;
}
.addOldDue-Row-1 {
  background: #fff;
  border: 2px solid rgba(18, 58, 188, 0.75);
  border-radius: 10px;
}
.form-control-dharam {
  width: 97%;
  height: 40px;
  padding: 11px;
  border-radius: 5px;
  margin: 1px 10px;
  border: none;
}
.form-control-gh {
  width: 100%;
  height: 60px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}
.hjkc {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background: rgba(18, 58, 188, 0.75);
  padding: 4px;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
}
.s000no {
  position: absolute;
  left: 0px;
  top: 77px;
  background: #f0ffffb3;
  width: 97px;
  text-align: center;
  font-weight: 700;
}
.checkbox-due-collecton {
  margin: 0 auto !important;
  width: 36px;
  height: 35px;
}
.due-main-ox {
  width: 45px;
  padding: 5px;
  border-radius: 4px;
  margin: 25px auto;
  height: 45px;
  background: rgba(18, 58, 188, 0.75);
}
.ed-1 {
  background: rgba(18, 58, 188, 0.75);
  padding: 5px;
  border-radius: 5px;
  color: #fff;
}
.viv {
  text-align: center;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
}
.d-150 {
  background: #fff;
  padding: 50px;
  margin: 0px 150px;
  border-radius: 10px;
}
/* Add media queries for smaller screens */
@media screen and (max-width: 720px) {
  .weekdays li,
  .days li {
    width: 13.1%;
  }
}

@media screen and (max-width: 420px) {
  .weekdays li,
  .days li {
    width: 12.5%;
  }
  .days li .active {
    padding: 2px;
  }
}

@media screen and (max-width: 290px) {
  .weekdays li,
  .days li {
    width: 12.2%;
  }
}
/* Add css for mobile view */

@media (max-width: 768px) {
  .mar-t {
    /* margin: -10px -30px 0px!important; */
    background: #7a81a9;
  }
  .fee-collection {
    left: 4%;
    top: 31%;
  }
  .or-cust {
    border: 1px solid #fff;
    width: 50px;
    height: 50px;
    font-size: 15px;
    padding: 15px 8px;
    background: #7a81a9;
    border-radius: 50%;
    color: #fff;
    margin: 5px auto;
  }
}

/* Add css for print view */

@media print {
  @page {
    size: 100%; /* landscape */
    /* you can also specify margins here: */
    margin: 0mm;
    margin-right: 0mm; /* for compatibility with both A4 and Letter */
  }
  .page-header,
  .left-sidebar,
  .content-header,
  .prin {
    display: none;
  }
  .content {
    margin: 0px;
  }
  .ht {
    width: 100%;
    margin: 0 auto;
  }

  .bg-white-11 {
    background: #fffffff0 !important;
  }
  .vvb {
    background: url(/static/media/EduOk.33c109dc.png) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
  .fg td {
    padding: 0px 5px;
    font-size: 19px;
  }

  html,
  body {
    height: 100vh;
    overflow: hidden;
  }
  .basic-de {
    background-color: #007bff80 !important;
  }
  .dharam {
    border: 1px solid #000;
  }
}

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

:root {
    --primary_color_shade0:#E1F5FE;
    --primary_color_shade1:#B3E5FC;
    --primary_color_shade2:#81D4FA;
    --primary_color_shade3:#4FC3F7;
    --primary_color_shade4:#29B6F6;
    --primary_color_shade5:#03A9F4;
    --primary_color_shade6:#039BE5;
    --primary_color_shade7:#0288D1;
    --primary_color_shade8:#0277BD;
    --primary_color_shade9:#01579B;

    --highlight_color1:#FE488B;
    --highlight_color2:#FFF307;
    --highlight_color3:#0765D6;
    --highlight_color4:#45DD03;

    --card_orange:#FF7043;
    --card_green:#89BE4C;
    --card_blue:#39AFD5;
    --card_deep_purple:#7e57c2;
    --card_voilet:#702b56;
    --card_red:#ef5350;
    --card_magenta:#CD014D;
    --card_cyan_green:#02BBA1;
    --card_amber:#FFB300;
    --card_burnt_orange:#EE4E34;
    --card_peach:#FCEDDA;

    --green_dark:#33b536;
    --pesto_green:#00AA8D;
    --pesto_green_lighter:#00BF9A;
    --pesto_green_darker:#008975;
    --leaf_green:#0C7300;
    --white:#FFFFFF;
    --black:#000;
    --blue:#0765D6;
    --voilet:#702b56;
    --holiday:#FFA000;
    --transparent:#FFffffff;

    --disalbed:#F5F5F5;
    --background_grey:#E0E0E0;
    --background_grey_dark:#BDBDBD;
    --grey0:#FAFAFA;
    --grey1:#F5F5F5;
    --grey2:#EEEEEE;
    --grey3:#E0E0E0;
    --grey4:#BDBDBD;
    --grey5:#9E9E9E;
    --grey_light:#dad9de;
    --green_light:#e4f9e4;

    --black9:#191919;
    --black8:#333333;
    --black7:#4c4c4c;
    --black6:#666666;
    --black5:#7f7f7f;
    --black4:#999999;
    --black3:#b2b2b2;
    --black2:#cccccc;
    --black1:#e5e5e5;

    --amber0:#FFF8E1;
    --amber1:#FFECB3;
    --amber2:#FFE082;
    --amber3:#FFD54F;
    --amber4:#FFCA28;
    --amber5:#FFC107;
    --amber6:#FFB300;
    --amber7:#FFA000;
    --amber8:#FF8F00;
    --amber9:#FF6F00;

    --green0:#AAFD86;
    --green1:#71FC36;
    --green2:#45DD03;
    --green3:#38B503;
    --green4:#2C8D02;
    --green5:#257502;
    --green6:#1F6402;
    --green7:#195001;
    --green8:#133C01;
    --green9:#0C2801;

    --Deep_Purple0:#ede7f6;
    --Deep_Purple1:#d1c4e9;
    --Deep_Purple2:#b39ddb;
    --Deep_Purple3:#9575cd;
    --Deep_Purple4:#7e57c2;
    --Deep_Purple5:#673ab7;
    --Deep_Purple6:#5e35b1;
    --Deep_Purple7:#512da8;
    --Deep_Purple8:#4527a0;
    --Deep_Purple9:#311b92;

    --radish0:#FDEDED;
    --radish1:#FBDADC;
    --radish2:#F6B6B8;
    --radish3:#F4A4A6;
    --radish4:#F07F83;
    --radish5:#EE6E73;
    --radish6:#EA484E;
    --radish7:#E5242A;
    --radish8:#C9181D;
    --radish9:#A41318;
    --radish10:#800F13;

   --red0:#ffebee;
   --red1:#ffcdd2;
   --red2:#ef9a9a;
   --red3:#e57373;
   --red4:#ef5350;
   --red5:#f44336;
   --red6:#e53935;
   --red7:#d32f2f;
   --red8:#c62828;
   --red9:#b71c1c;
    --navy_blue0:#97ADD8;
    --navy_blue1:#7995CD;
    --navy_blue2:#5B7EC2;
    --navy_blue3:#4268B3;
    --navy_blue4:#375695;
    --navy_blue5:#2A4272;
    --navy_blue6:#273C68;
    --navy_blue7:#213459;
    --navy_blue8:#1C2B4A;
    --navy_blue9:#16233C;


    --light_blue0:#E1F5FE;
    --light_blue1:#B3E5FC;
    --light_blue2:#81D4FA;
    --light_blue3:#4FC3F7;
    --light_blue4:#29B6F6;
    --light_blue5:#03A9F4;
    --light_blue6:#039BE5;
    --light_blue7:#0288D1;
    --light_blue8:#0277BD;
    --light_blue9:#01579B;



    --deeporange0:#FBE9E7;
    --deeporange1:#FFCCBC;
    --deeporange2:#FFAB91;
    --deeporange3:#FF8A65;
    --deeporange4:#FF7043;
    --deeporange5:#FF5722;
    --deeporange6:#F4511E;
    --deeporange7:#E64A19;
    --deeporange8:#D84315;
    --deeporange9:#BF360C;


    --indian_saffron0:#ffcbab;
   --indian_saffron1:#ffb384;
    --indian_saffron2:#ff9b5d;
    --indian_saffron3:#ff8f49;
    --indian_saffron4:#ff8336;
    --indian_saffron5:#ff7722;
   --indian_saffron6:#ff6b0e;
    --indian_saffron7:#fa6000;
   --indian_saffron8:#e65900;
   --indian_saffron9:#d35100;


    --rad-50: 50%;
    --rad-100: 100%;
  }
  p {
    margin-bottom: 0px;    
  }
  .row {
    padding: 0px;
    margin: 0px;
  }
 
  
.cursor{
    cursor:pointer;
}
.padding_0{
    padding: 0px;
}
.padding_5{
    padding: 5px;
}
.padding_10{
    padding: 10px;
}

.margin_0{
    margin: 0px;
}
.margin_3{
    margin: 3px;
}
.margin_5{
    margin: 5px;
}
.margin_10{
        margin: 10px;
}
.height_match_parent{
    height:100%;
    
}
.width_match_parent{
    width:100%;
    
}
.match_parent{
    height:100%;
    width:100%;
}
.width_fill_parent{
    width: 100vw;
}
.txt_white{
    color: var(--white);
}
.black{
    color: var(--black);
}
.black9{
    color: var(--black9);
}
.black8{
    color: var(--black8);
}
.black7{
    color: var(--black7);
}
.black6{
    color: var(--black6);
}
.black5{
    color: var(--black5);
}
.black4{
    color: var(--black4);
}
.bg_grid {
    border: 1px solid var(--grey4);  
}
.h_30{
    height:30px;
}
.h_35{
    height:35px;
}
.h_40{
    height:40px;
}
.h_45{
    height:45px;
}
.h_50{
    height:50px;
}
.h_55{
    height:55px;
}
.h_60{
    height:60px;
}
.h_65{
    height:65px;
}
.h_70{
    height:70px;
}
.h_75{
    height:75px;
}
.h_80{
    height:80px;
}
.h_85{
    height:85px;
}
.h_90{
    height:90px;
}
.h_95{
    height:95px;
}
.h_100{
    height:100px;
}

.w_30{
    width:30px;
}
.w_35{
    width:35px;
}
.w_40{
    width:40px;
}
.w_45{
    width:45px;
}
.w_50{
    width:50px;
}
.w_55{
    width:55px;
}
.w_60{
    width:60px;
}
.w_65{
    width:65px;
}
.w_70{
    width:70px;
}
.w_75{
    width:75px;
}
.w_80{
    width:80px;
}
.w_85{
    width:85px;
}
.w_90{
    width:90px;
}
.w_95{
    width:95px;
}
.w_100{
    width:100px;
}

.bg_grid_white {
    border: 1px solid var(--white);  
}
.bg_grid_white_2 {
    border: 2px solid var(--white);  
}
.bg_grid_primary {
    border: 1px solid var(--primary_color_shade4);  
}
.bg_grid_primary_2 {
    border: 2px solid var(--primary_color_shade4);  
}
.grid_primery_outline_primery {

    border: 2px solid var(--primary_color_shade6);
    padding: 2px 2px 2px 2px;
    margin: 3px 0px 3px 0px;
    text-align: center;
    font-style: normal;
    color:var(--white);
    background-color:var(--primary_color_shade4);

}

  .bg_white_outline_grey {
    border-radius: 5px;
    border: 2px solid var(--grey4);
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 40px;
    margin-top: 3px;
    font-style: normal;
    color:var(--grey5);
    background-color:var(--white);

}
.bg_green_outline_green {
    border-radius: 5px;
    border: 2px solid var(--green5);
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 40px;
    margin-top: 3px;
    font-style: normal;
    color:var(--white);
    background-color:var(--green3);

}
.bg_class_deselect {
    border-radius: 5px;
    border: 2px solid #fff;
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 40px;
    margin-top: 3px;
    font-style: normal;
    color: white;

}
.bg_class_deselect_grey{
    border-radius: 5px;
    border: 2px solid var(--grey5);
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 40px;
    margin-top: 3px;
    font-style: normal;
    color: var(--grey5);

}
.bg_class_deselect_grey2{
    border-radius: 5px;
    border: 2px solid var(--grey5);
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 80px;
    margin-top: 3px;
    font-style: normal;
    color: var(--grey5);

}
.gravity_center{
    display: flex;
    justify-content: center;
    align-items: center;
  
}
.gravity_center_vertical{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}
.gravity_center_horizontal{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
}
.gravity_center_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
  
}

.align_center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.content_center{
    margin-top:auto;
    margin-bottom:auto;
    text-align:center;

}
.gravity_center_right{
    display: flex;
    justify-content: flex-end;  
}
.bg_class_select {
    border-radius: 5px;
    border: 2px solid #0288D1;
    background-color: #FFF307;
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 40px;
    margin-top: 3px;
    font-style: normal;
    color: #FE488B;

}
.bg_class_select2 {
    border-radius: 5px;
    border: 2px solid #0288D1;
    background-color: #FFF307;
    padding: 2px 2px 2px 2px;
    text-align: center;
    height: 80px;
    margin-top: 3px;
    font-style: normal;
    color: #FE488B;

}
.bg_card_primary {
    border-radius: 10px;
    border: 2px solid #fff;
    background-color: var(--primary_color_shade5);
    
}
.bg_card_primary_outline {
    border-radius: 10px;
    border: 2px solid var(--primary_color_shade5);
    background-color: #FFF;
   
}
.bg_card_red {
    border-radius: 10px;
    border: 2px solid var(--red2);
    background-color: #ef5350;
    
}
.bg_card_red_outline {
    border-radius: 10px;
    border: 2px solid var(--card_red);
    background-color: #FFF;
   
}
.bg_card_orange {
    border-radius: 10px;
    border: 2px solid var(--deeporange2);
    background-color: var(--card_orange);
    
}
.bg_card_orange_outline {
    border-radius: 10px;
    border: 2px solid var(--card_orange);
    background-color: #FFF;
   
}
.bg_card_green {
    border-radius: 10px;
    border: 2px solid  var(--green1);
    background-color: var(--card_green);
    
}
.bg_card_green_outline {
    border-radius: 10px;
    border: 2px solid #89BE4C;
    background-color: #FFF;
   
}
.bg_card_deep_purple_outline {
    border-radius: 10px;
    border: 2px solid var(--card_deep_purple);
    background-color: #FFF;
   
}
.bg_card_deep_purple {
    border-radius: 10px;
    border: 2px solid var(--Deep_Purple2);
    background-color: var(--card_deep_purple);;
   
}
.bg_round_green {
    border-radius: 10px;
    background-color: #89BE4C;
    
}
.bg_card_grey {
    border-radius: 10px;
    border: 3px solid #fff;
    background-color: #BDBDBD;
    
}
.bg_card_grey_outline {
    border-radius: 10px;
    border: 2px solid #BDBDBD;
    background-color: #FFF;
   
}
.bg_round_grey {
    border-radius: 10px;
    background-color: #BDBDBD;
    
}
.bg_corner_round_grey {
    border-radius: 10px;
    background-color: var(--white);
    border: 1px solid var(--grey4);
    
}
.bg_corner_round_green {
    border-radius: 10px;
    background-color: var(--white);
    border: 1px solid var(--green4);
    
}
.bg_card_blue_outline{
    border-radius: 10px;
    border: 2px solid var(--card_blue);
    background-color: #FFF;   
   
}
.bg_card_blue {
    border-radius: 10px;
    border: 2px solid var(--light_blue1);;
    background-color: var(--card_blue);
    
}
.bg_card_blue_outline_nofill{
    border-radius: 10px;
    border: 3px solid var(--card_blue);
    
}
.bg_card_grey_tltr {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 2px solid #BDBDBD;
    background-color: #BDBDBD;
   
}
.bg_card_green_tltr {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: var(--card_green);
   
}
.bg_card_blue_tltr {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: var(--card_blue);
   
}
.bg_primery_t10_b30 {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: var(--primary_color_shade5);
    
}
.bg_white_t30_b10 {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: var(--white);
    
}
.bg_grey{
    background-color: #E0E0E0;
    
}
.bg_circle_primary_outline_yellow{
    background: var(--primary_color_shade5);
    border: 3px solid var(--highlight_color2);
    border-radius:100px;
}
.bg_rect_primary_outline_yellow{
    background: var(--primary_color_shade5);
    border: 3px solid var(--highlight_color2);
    border-radius:50px;
}
.bg_rect_grey_outline_primery{
    background: var(--grey2);
    border: 5px solid var(--primary_color_shade8);
    border-radius:20px;
}
.bg_circle_fill_primery_grey_outline{
    width: 120px;
    height: 120px;
    background: #29B6F6;
    border: 4px solid #E0E0E0;
    border-radius: 50%;
    
}

.bg_circle_fill_red_grey_outline{
    width: 120px;
    height: 120px;
    background: #ef5350;
    border: 4px solid #E0E0E0;
    border-radius: 50%;
}
.bg_circle_outline_primery{
    width: var(--mWidth);
    height: var(--mHeight);
    background: var(--white);
    border: var(--mBorder) solid var(--primary_color_shade5);
    border-radius: 50%;
}
.bg_circle_outline_grey{
    width: var(--mWidth);
    height: var(--mHeight);
    background: var(--white);
    border: var(--mBorder) solid var(--grey3);
    border-radius: 50%;
   
}
.bg_circle_primery{
    width: 120px;
    height: 120px;
    background: var(--primary_color_shade5);
    border: 3px solid var(--primary_color_shade2);
    border-radius: 50%;
    
}
.bg_circle_primery1{
    background: var(--primary_color_shade5);
    border: 1px solid var(--primary_color_shade2);
    border-radius: 50%;
    
}

.bg_circle_card_orange{
    width: 120px;
    height: 120px;
    background: var(--card_orange);
    border: 3px solid var(--deeporange3);
    border-radius: 50%;
}
.bg_circle_amber{
    width: 120px;
    height: 120px;
    background: var(--amber4);
    border: 3px solid var(--amber2);
    border-radius: 50%;
}
.bg_circle_card_red{
    width: 120px;
    height: 120px;
    background: var(--card_red);;
    border: 3px solid var(--red2);
    border-radius: 50%;
}
.bg_page_smokewhite_rounded{
    background: var(--whitesmoke);
    padding: 10px 10px 20px 10px;
    margin: 0px var(--mMargin) 0px var(--mMargin);
    border-radius: 10px;
}

.bg_page_white_rounded{
    background: #fff;
    padding: 10px 10px 20px 10px;
    margin: 0px var(--mMargin) 0px var(--mMargin);
    border-radius: 10px;
}
.bg_page_grey_rounded{
    background: var(--grey3);
    padding: 10px 10px 20px 10px;
    margin: 0px var(--mMargin) 0px var(--mMargin);
    border-radius: 10px;
}
.bg_page_greylite_rounded{
    background: var(--grey1);
    padding: 10px 10px 20px 10px;
    margin: 0px var(--mMargin) 0px var(--mMargin);
    border-radius: 10px;
}
.bg_page_primary_rounded{
    background: var(--primary_color_shade0);
    padding: 10px 10px 20px 10px;
    margin: 0px var(--mMargin) 0px var(--mMargin);
    border-radius: 10px;
}
.bg_smokewhite_blbr{
    background: whitesmoke;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
   
}
.bg_smokewhite_tltr{
    background: whitesmoke;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
   
}
.bg_smokewhite{
    background: whitesmoke;
    border: 3px solid var(--grey4);
    border-radius: 8px;
   
}
.circular_image{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid var(--primary_color_shade1);
    overflow: hidden;
    display:inline-block;
    vertical-align:middle;
    background: var(--primary_color_shade2);
    overflow:hidden;

}
.circular_image_student_details{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid var(--primary_color_shade1);
    overflow: hidden;
    display:inline-block;
    vertical-align:middle;
    background: var(--primary_color_shade2);
    overflow:hidden;

}
.circular_image_student_details img{
    width:100%;
  }


.rect_image_view{
    width: 80px;
    height:100px;
    border: 2px solid var(--grey3);
    overflow: hidden;
    display:inline-block;
    vertical-align:middle;
    background: var(--whitesmoke);
    overflow:hidden;
}
.rect_image_view img{
    width: 100%;
    height:100%;
   
}
.circular_image_primery{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid var(--primary_color_shade1);
    background: var(--primary_color_shade4);
    overflow:hidden;

}
.circular_image_custom{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: var(--myBorder) solid var(--myBorderColor);
    overflow: hidden;
    background: var(--myFillColor);
   
}

  .circular_image img{
    width:100%;
  }
 
.card{

    background-color: #f1f1f1;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;

}
.viewHide{
    display: none;
}
.Hidden{
    display: none;
}
.viewShow{
    display: block;
}
.text-center{
    text-align: center;
    margin: auto;
    display:inline-block;
    position:relative;

}
.box_shadow{   
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
}

.box_shadow_primary{   
    box-shadow: 0 2px 4px 0 var(--primary_color_shade2),0 2px 10px 0 var(--primary_color_shade2)!important;
}

.box_shadow_highlight1{   
    box-shadow: 0 2px 4px 0 var(--highlight_color1),0 2px 10px 0 var(--highlight_color1)!important;
}

.box_shadow_highlight2{   
    box-shadow: 0 2px 4px 0 var(--highlight_color2),0 2px 10px 0 var(--highlight_color2)!important;
}

.bg_circle_green_check{
    background: var(--green3);
    width: 50px;
    height: 50px;
    font-weight: 900;
    padding: 2px;
    border-radius: 50%;
    color: var(--white);
    margin: 0 auto;
    font-size: 28px;
    text-align: center;
}
.bg_circle_red_check{
    background: var(--red4);
    width: 50px;
    height: 50px;
    font-weight: 900;
    padding: 2px;
    border-radius: 50%;
    color: var(--white);
    margin: 0 auto;
    font-size: 28px;
    text-align: center;
}
.bg_circle_primery_check{
    background: var(--primary_color_shade3);
    width: 50px;
    height: 50px;
    font-weight: 900;
    padding: 2px;
    border-radius: 50%;
    color: var(--white);
    margin: 0 auto;
    font-size: 28px;
    text-align: center;
}
.nowrap {
    white-space: nowrap ;
  }
.edt_address{
        height: 60px;
        width: 100%;
        font-size: 18px;
        padding: 3px;
        margin-top: 5px;
        margin-bottom: 5px;
}

.check_box1{
    transform: scale(1.5);
   
}
.img_sample_docs{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid var(--primary_color_shade1);
    overflow: hidden;
    display:inline-block;
    vertical-align:middle;
    background: var(--primary_color_shade2);
    overflow:hidden;
}
.img_sample_docs img{
    width:100%;
    height: 100%;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTa-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTZX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTRWV49_lSm1NYrwo-zkhivY.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTaaRobkAwv3vxw3jMhVENGA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTf8zf_FOSsgRmwsS7Aa9k2w.woff2) format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTT0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/K88pR3goAWT7BTt32Z01mxJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/RjgO7rYTmqiVp7vzi-Q5URJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/LWCjsQkB6EMdfHrEVqA1KRJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/xozscpT2726on7jbcb_pAhJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/59ZRklaO5bWGqF5A9baEERJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/u-WUoqrET9fUeobQW7jkRRJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSq-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSpX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNShWV49_lSm1NYrwo-zkhivY.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSqaRobkAwv3vxw3jMhVENGA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSv8zf_FOSsgRmwsS7Aa9k2w.woff2) format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSj0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzK-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzJX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzBWV49_lSm1NYrwo-zkhivY.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzKaRobkAwv3vxw3jMhVENGA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzP8zf_FOSsgRmwsS7Aa9k2w.woff2) format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzD0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* add new css for admin-menu-img-size */

.admin-menu-img-size{
  width: 30%;
  margin: 0px 10px;
  background: #464d76;
  padding: 5px;

}

.left-sidebar {
  display: block;
  height: 100%;
  float: left;
  position: relative;
  width: 250px;
  z-index: 1030;
}
.content {
  display: block;
  position: relative;
  vertical-align:top;
  margin-left: 250px;
  padding: 30px;
  background: #ececec;
  min-height: 100vh;
}
ul#main-nav li span {
  vertical-align: middle;
  font-size: 16px;
}

@media only screen and (min-width: 768px)
{
html.fixed .content-header {
    position: fixed;
    z-index: 1010;
    top: 100px;
    left: 250px;
    right: 0;
    margin: 0;
    transition-property: left;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    transition-delay: 0;
}
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

:root {
  --primary_color_shade0: #e1f5fe;
  --primary_color_shade1: #b3e5fc;
  --primary_color_shade2: #81d4fa;
  --primary_color_shade3: #4fc3f7;
  --primary_color_shade4: #29b6f6;
  --primary_color_shade5: #03a9f4;
  --primary_color_shade6: #039be5;
  --primary_color_shade7: #0288d1;
  --primary_color_shade8: #0277bd;
  --primary_color_shade9: #01579b;

  --highlight_color1: #fe488b;
  --highlight_color2: #fff307;
  --highlight_color3: #0765d6;
  --highlight_color4: #45dd03;

  --card_orange: #ff7043;
  --card_green: #89be4c;
  --card_blue: #39afd5;
  --card_deep_purple: #7e57c2;
  --card_voilet: #702b56;
  --card_red: #ef5350;
  --card_magenta: #cd014d;
  --card_cyan_green: #02bba1;
  --card_amber: #ffb300;

  --green_dark: #33b536;
  --pesto_green: #00aa8d;
  --pesto_green_lighter: #00bf9a;
  --pesto_green_darker: #008975;
  --leaf_green: #0c7300;
  --white: #ffffff;
  --black: #000;
  --blue: #0765d6;
  --voilet: #702b56;
  --holiday: #ffa000;
  --transparent: #ffffffff;

  --disalbed: #f5f5f5;
  --background_grey: #e0e0e0;
  --background_grey_dark: #bdbdbd;
  --grey0: #fafafa;
  --grey1: #f5f5f5;
  --grey2: #eeeeee;
  --grey3: #e0e0e0;
  --grey4: #bdbdbd;
  --grey5: #9e9e9e;
  --grey_light: #dad9de;
  --green_light: #e4f9e4;

  --black9: #191919;
  --black8: #333333;
  --black7: #4c4c4c;
  --black6: #666666;
  --black5: #7f7f7f;
  --black4: #999999;
  --black3: #b2b2b2;
  --black2: #cccccc;
  --black1: #e5e5e5;

  --amber0: #fff8e1;
  --amber1: #ffecb3;
  --amber2: #ffe082;
  --amber3: #ffd54f;
  --amber4: #ffca28;
  --amber5: #ffc107;
  --amber6: #ffb300;
  --amber7: #ffa000;
  --amber8: #ff8f00;
  --amber9: #ff6f00;

  --green0: #aafd86;
  --green1: #71fc36;
  --green2: #45dd03;
  --green3: #38b503;
  --green4: #2c8d02;
  --green5: #257502;
  --green6: #1f6402;
  --green7: #195001;
  --green8: #133c01;
  --green9: #0c2801;

  --Deep_Purple0: #ede7f6;
  --Deep_Purple1: #d1c4e9;
  --Deep_Purple2: #b39ddb;
  --Deep_Purple3: #9575cd;
  --Deep_Purple4: #7e57c2;
  --Deep_Purple5: #673ab7;
  --Deep_Purple6: #5e35b1;
  --Deep_Purple7: #512da8;
  --Deep_Purple8: #4527a0;
  --Deep_Purple9: #311b92;

  --radish0: #fdeded;
  --radish1: #fbdadc;
  --radish2: #f6b6b8;
  --radish3: #f4a4a6;
  --radish4: #f07f83;
  --radish5: #ee6e73;
  --radish6: #ea484e;
  --radish7: #e5242a;
  --radish8: #c9181d;
  --radish9: #a41318;
  --radish10: #800f13;

  --red0: #ffebee;
  --red1: #ffcdd2;
  --red2: #ef9a9a;
  --red3: #e57373;
  --red4: #ef5350;
  --red5: #f44336;
  --red6: #e53935;
  --red7: #d32f2f;
  --red8: #c62828;
  --red9: #b71c1c;
  --navy_blue0: #97add8;
  --navy_blue1: #7995cd;
  --navy_blue2: #5b7ec2;
  --navy_blue3: #4268b3;
  --navy_blue4: #375695;
  --navy_blue5: #2a4272;
  --navy_blue6: #273c68;
  --navy_blue7: #213459;
  --navy_blue8: #1c2b4a;
  --navy_blue9: #16233c;

  --light_blue0: #e1f5fe;
  --light_blue1: #b3e5fc;
  --light_blue2: #81d4fa;
  --light_blue3: #4fc3f7;
  --light_blue4: #29b6f6;
  --light_blue5: #03a9f4;
  --light_blue6: #039be5;
  --light_blue7: #0288d1;
  --light_blue8: #0277bd;
  --light_blue9: #01579b;

  --deeporange0: #fbe9e7;
  --deeporange1: #ffccbc;
  --deeporange2: #ffab91;
  --deeporange3: #ff8a65;
  --deeporange4: #ff7043;
  --deeporange5: #ff5722;
  --deeporange6: #f4511e;
  --deeporange7: #e64a19;
  --deeporange8: #d84315;
  --deeporange9: #bf360c;

  --indian_saffron0: #ffcbab;
  --indian_saffron1: #ffb384;
  --indian_saffron2: #ff9b5d;
  --indian_saffron3: #ff8f49;
  --indian_saffron4: #ff8336;
  --indian_saffron5: #ff7722;
  --indian_saffron6: #ff6b0e;
  --indian_saffron7: #fa6000;
  --indian_saffron8: #e65900;
  --indian_saffron9: #d35100;

  --rad-50: 50%;
  --rad-100: 100%;

  --peach: #fcedda;
  --burent_orange: #ee4e34;
}

.table_border {
  border: 2px solid black;
  margin: 10px 30px 10px 30px;
}

.table_decoration {
  padding: 10px;
  font-size: large;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.table_decoration1 {
  /* padding: 10px; */
  font-size: large;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
}

.admission_data {
  padding: 5px;
  font-size: medium;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.table_data {
  color: #6494cf;
  padding: 5px;
  font-size: medium;
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.admission_data1 {
  text-align: center;
  font-family: "Courier New", Courier, monospace;
}

.mar_top {
  margin-top: 20px;
}

.mar_top1 {
  margin-top: 8px;
}

.mar_top2 {
  margin-top: 2px;
}

.padding_2 {
  padding: 2px;
}

.mp1_0 {
  padding: 0px;
  margin: 0px;
}

.admission_form {
  font-size: large;
  font-weight: 800;
  text-align: center;
  margin-left: 25px;
  font-family: "Courier New", Courier, monospace;
}

.admission_form2 {
  font-size: large;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
}

.new_admission {
  font-size: large;
  font-family: "Courier New", Courier, monospace;
  text-align: left;
  font-weight: 800;
}

.admission_radio {
  margin-left: 30px;
}

.admission_radio1 {
  margin-left: 15px;
}

.btn_padding {
  padding: 30px;
  font-size: larger;
  font-weight: 700;
  height: 300px;
  width: 300px;
}

.mar_transfer {
  margin-left: 40px;
  margin-right: 10px;
}

.admission_prvform2 {
  font-size: large;
  font-weight: 800;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
}

.admission_prvform1 {
  font-size: large;
  font-weight: 800;
  text-align: left;
  font-family: "Courier New", Courier, monospace;
}

.admission_prvform2 {
  font-size: 17px;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
}

.admission_prvform3 {
  font-size: large;
  font-weight: 800;
  text-align: right;
  font-family: "Courier New", Courier, monospace;
}

.margin_2 {
  padding: -1px;
}

.email_mar_left {
  margin-left: 6px;
}

.circular_image1 {
  width: 150px;
  height: 180px;
  border-radius: 30%;
  border: 3px solid var(--grey4);
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  background: var(--whitesmoke);
  overflow: hidden;
}

.circular_image2 {
  width: 100px;
  height: 100px;
  border-radius: 30%;
  border: 3px solid var(--grey4);
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  background: var(--whitesmoke);
  overflow: hidden;
}

.img_staff1 {
  width: 100%;
  height: 100%;
}

.mar_bottom {
  margin-bottom: 15px;
}

.mar_left1 {
  margin-left: 60px;
}

.margin_left1 {
  margin-left: 5px;
}

.bg_admission {
  background-color: var(--primary_color_shade8);
  color: var(--white);
}

.bg_card_blue_outline1 {
  border-radius: 50px;
  border: 8px solid var(--primary_color_shade2);
}

.font_size {
  font-size: x-large;
  font-weight: lighter;
}

.font_size1 {
  font-size: 55px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: lighter;
}

.font_size2 {
  font-size: 15px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: lighter;
}

.font_size3 {
  font-size: 0px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  /* font-weight: lighter; */
}

.border_form {
  border: 1px solid;
  height: 30px;
}

.border_form1 {
  border: 1px solid;
  height: 130px;
}

.border_form2 {
  border: 3px solid #4fc3f7;
  border-radius: 3px;
}

.margin_left {
  margin-left: 10px;
  margin-right: 10px;
}

.bt_class {
  background-color: none;
}

.form-control1 {
  width: 250px;
  border-radius: 5px;
  border: none;
  margin-left: 10px;
}

.bg_grid_primary1 {
  text-align: center;
  border: 5px solid var(--primary_color_shade4);
  color: #aafd86;
  border-radius: 5px;
}

.mar_right1 {
  margin-right: 7px;
  border-radius: 2px;
}

.word_center {
  text-align: center;
}

.admission_data2 {
  padding: 5px;
  font-size: medium;
  text-align: center;
  color: white;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.admission_data3 {
  padding: 1px;
  font-size: medium;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
}

.btn_set {
  margin-top: 3px;
  margin-bottom: 0px;
}

.fee_style {
  font-size: xx-large;
  font-weight: 800;
  text-align: center;
  margin-left: 25px;
  color: white;
  font-family: "times new roman";
}

.fee_style1 {
  font-size: x-large;
  font-weight: 600;
  text-align: center;
  margin-left: 25px;
  color: white;
  font-family: "Courier New";
}

.fee_style2 {
  font-size: large;
  font-weight: 600;
  text-align: center;
  margin-left: 25px;
  color: white;
  font-family: "times new roman";
}

.fee_style3 {
  font-size: small;
  font-weight: 500;
  text-align: center;

  color: white;
  font-family: "times new roman";
}

.fee_style4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.fee_style5 {
  font-size: large;
  text-align: start;
  color: white;
  font-family: "times new roman";
}

.i_font {
  color: #0765d6;
  font-size: medium;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.i_font:hover {
  cursor: pointer;
}

.bg_card_fee1 {
  text-align: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: "Times New Roman", Times, serif;
  color: white;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: var(--primary_color_shade5);
}

.bg_card_fee2 {
  text-align: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: "Times New Roman", Times, serif;
  color: white;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: var(--card_red);
}

.bg_card_fee3 {
  text-align: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: "Times New Roman", Times, serif;
  color: white;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: var(--card_blue);
}

.bg_card_fee4 {
  text-align: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: "Times New Roman", Times, serif;
  color: white;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: var(--card_orange);
}

.bg_card_fee5 {
  text-align: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: "Times New Roman", Times, serif;
  color: white;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: var(--grey5);
}

.subject_list {
  color: white;
  text-align: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: "Courier New", Courier, monospace;
}

.subject_list1 {
  color: rgb(88, 90, 226);
  text-align: right;
  font-size: 23px;
  font-weight: bolder;
  font-family: "Courier New", Courier, monospace;
}

.exam_section {
  background: #039be5;
  padding: 5px;
  border-radius: 10px;
}

.exam_section1 {
  background: #81d4fa;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

.bg_white1 {
  background: white;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
}

.text_bott1 {
  background: #fea223;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-weight: 600px;
  border-radius: 2px;
}

.subject_1 {
  font-weight: bolder;
  color: white;
  margin-left: 60px;
  font-family: "Courier New", Courier, monospace;
}

.subject_2 {
  font-size: 15px;
  font-weight: bolder;
  font-family: "Courier New", Courier, monospace;
}

.subject_4 {
  font-size: 15px;
  font-weight: bolder;
  font-family: "Courier New", Courier, monospace;
}

.subject_3 {
  color: white;
}

.bg_white_outline_blue1 {
  border-radius: 5px;
  border: 2px solid var(--blue);
  padding: 2px 2px 2px 2px;
  text-align: center;
  margin-top: 3px;
  font-style: normal;
  color: var(--black);
  background-color: var(--white);
}

.bg_card_blue1 {
  border-radius: 10px;
  border: 2px solid var(--light_blue1);
  background-color: var(--card_blue);
  text-align: center;
}

.bg_card_blue2 {
  border-radius: 10px;
  border: 2px solid var(--light_blue1);
  background-color: var(--card_blue);
  text-align: center;
}

.bg_class_select1 {
  border-radius: 5px;
  border: 2px solid #0288d1;
  background-color: white;
  padding: 2px 2px 2px 2px;
  text-align: center;
  height: 40px;
  margin-top: 3px;
  font-style: normal;
  color: #fe488b;
}

.btn_font_size {
  border-radius: 5px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: large;
  font-weight: bolder;
}

.delete_subject {
  padding: 5px;
  font-size: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.holiday {
  font-weight: bolder;
  font-size: large;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
}

.holiday1 {
  font-weight: bolder;
  font-size: small;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
}

.holiday2 {
  margin-left: 10px;
  font-weight: bolder;
  font-size: x-large;
  text-align: left;
  font-family: "Courier New", Courier, monospace;
}

.card_w img {
  width: 50%;
  height: 50%;
  border: 3px solid #454c76;
  padding: 2px;
  margin-left: 100px;
}

.margin_vp {
  margin-left: 80px;
  margin-right: 80px;
}

.just_text {
  text-justify: inter-word;
  justify-content: center;
}

.fee_border {
  padding: 3px;
  background-color: #039be5;
  color: white;
  border-radius: 5px;
}

.fee_remark {
  border: 2px solid gray;
  border-radius: 5px;
}

.home_work {
  text-align: center;
  margin-top: 10px;
}

.white {
  color: white;
}

.text_center1 {
  font-size: large;
  font-weight: 800;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
}

.form_control2 {
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: whitesmoke;
  color: #000;
  font-size: large;
  font-family: "Courier New", Courier, monospace;
  border-radius: 10px;
}

.text_center2 {
  font-size: large;
  font-weight: 800;
  text-align: left;
  font-family: "Courier New", Courier, monospace;
}

.table_border1 {
  border: 2px solid black;
  margin: 7px 3px 1px 3px;
  border-radius: 3px;
}

.staff1 {
  text-align: end;
}

.staff2 {
  text-align: center;
  margin-right: 115px;
}

.staff_role {
  width: 94%;
  padding: 4px;
}

.staff_mar {
  margin-left: 12px;
}

.bg_card_peach {
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: var(--card_peach);
}

.bg_card_peach_outline {
  border-radius: 10px;
  border: 2px solid var(--card_peach);
  background-color: #fff;
}

.bg_card_burnt_orange {
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: var(--card_burnt_orange);
}

.bg_card_burnt_orange_outline {
  border-radius: 10px;
  border: 2px solid var(--card_burnt_orange);
  background-color: #fff;
}

.text_burnt {
  /* color:#EE4E34 ; */
  text-align: center;
  font-size: larger;
}

.text_burnt1 {
  color: black;
  text-align: center;
  font-size: larger;
}

.staffcenter {
  text-align: center;
  color: black;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: x-large;
  font-weight: bolder;
}

.staffcenter1 {
  text-align: center;
  color: rgb(217, 59, 59);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: large;
}

.img-phto1 {
  width: 50%;
  /* height: 39%; */
  margin: 10px;
}

.text-center1 {
  text-align: center;
  margin: auto;
  position: relative;
}

.bg-info1 {
  border-radius: 5px;
  background-color: #41a6dd;
}

.gravity_center1 {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.student_id1 {
  position: absolute;
  width: 100%;
  background: #ee4e34;
  padding: 0px 5px;
  color: var(--black);
  font-size: 16px;
  font-weight: bold;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.class_subject {
  color: black;
  text-align: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: "Courier New", Courier, monospace;
}

.abcd {
  width: 25px;
  height: 25px;
}

.delete_student {
  border-radius: 11px;
  background-color: red;
  font-size: 35px;
  text-align: center;
  font-weight: 100;
}

.check_staff {
  height: 40px;
  width: 40px;
}

.exam_list_margin {
  margin: 5px;
}

.deleteExam_degin {
  text-align: center;
  color: #01579b;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.bg_circle_card_peach {
  width: 120px;
  height: 120px;
  background: var(--peach);
  /* border: 3px solid var(--deeporange3); */
  border-radius: 50%;
}

.text_color {
  color: #ee4e34;
}

.class-list-box1 {
  padding: 2px;
  background-color: floralwhite;
  margin: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.admission_deshboard_div {
  padding: 1rem;
  background: #039be5;
  border-radius: 5px;
}

.admission_deshboard_Second_div {
  height: 75px;
  font-size: 0px;
}

.Stu_mark {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.info-summary {
  max-width: 100%;
  margin: auto;
  overflow-x: auto;
  /* To handle overflow on smaller screens */
  border: 2px solid var(--red3);
  /* Red border */
  border-radius: 10px;
  /* Rounded corners */
  padding: 10px;
  /* Padding inside the box */
  background-color: #fff;
  /* Background color of the box */
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  /* Ensure items do not wrap */
}

.info-item {
  flex: 1 1 auto;
  margin: 0;
  /* Remove margins for a single line display */
  white-space: nowrap;
  /* Prevent line breaks within the items */
}

.info-item p {
  margin: 0;
  padding: 0;
  /* Remove padding from paragraphs */
  display: flex;
  align-items: center;
}

input[type="number"] {
  width: 80px;
  padding: 5px;
  margin-left: 5px;
  /* Space between label and input */
  box-sizing: border-box;
  border: 1px solid orange;
  /* Light border for the input */
  border-radius: 5px;
  /* Rounded corners for the input */
  font-size: 1rem;
  /* Consistent font size */
}

/* Vehicles_Transport General Styles */

/* Main Container */
.Vehicles_Transport-container {
  background: url(/static/media/bg_1.d06b986b.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Header */
.Vehicles_Transport-staffcenter {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #ff6f61, #ffa726, #ffca28);
  border-radius: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Add Button */
.Vehicles_Transport-add-button {
  margin: 2px;
  padding: 7px;
  background: linear-gradient(135deg, #66bb6a, #43a047);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.4s ease;
}

.Vehicles_Transport-add-button:hover {
  background-color: #388e3c;
}

/* Vehicle Card */
.Vehicles_Transport-vehicle-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 15px;
  /* Reduced padding to decrease height */
  margin-bottom: 20px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border: 2px solid #f57c00;
}

.Vehicles_Transport-vehicle-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

/* Vehicle Image */
.Vehicles_Transport-vehicle-image-container {
  background-color: white;
  border-radius: 50%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border: 2px solid #ff7043;
}

.Vehicles_Transport-vehicle-image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.Vehicles_Transport-vehicle-card .col-md-4 {
  display: flex;
  justify-content: flex-start;
  /* Align image to the left */
}

/* Vehicle Details */
.Vehicles_Transport-vehicle-details p {
  margin: 5px 0;
  font-size: 14px;
  color: #444;
}

.Vehicles_Transport-vehicle-details p strong {
  color: #f57c00;
}

.Vehicles_Transport-vehicle-details hr {
  margin: 8px 0;
  border-color: #f57c00;
}

/* Edit/Delete Buttons */
.edit_button,
.Vehicles_Transport-delete-button {
  padding: 6px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.4s ease;
  width: 60px;
  text-align: center;
}

/* Edit Button */
.edit_button {
  background: linear-gradient(135deg, #42a5f5, #1976d2);
  color: white;
  margin-right: 10px;
  /* Added margin for gap between edit and delete buttons */
}

.edit_button:hover {
  background-color: #1565c0;
}

/* Delete Button */
.Vehicles_Transport-delete-button {
  background: linear-gradient(135deg, #ef5350, #d32f2f);
  color: white;
}

.Vehicles_Transport-delete-button:hover {
  background-color: #c62828;
}

.button_vehicle {
  grid-gap: 10px;
  gap: 10px;
  /* Adjusted gap for better spacing */
  flex-direction: row-reverse;
  display: flex;
  justify-content: flex-end;
}

.addTransportVehicle-wrapper {
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  /* Soft gradient background */
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.addTransportVehicle-header {
  background: linear-gradient(135deg, #ff6f61, #ffa726, #ffca28);
  color: white;
  padding: 10px;
}

.addTransportVehicle-title {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.addTransportVehicle-body {
  padding: 15px;
}

.addTransportVehicle-formRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.addTransportVehicle-formField {
  flex: 1 1;
  margin-right: 10px;
}

.addTransportVehicle-formField:last-child {
  margin-right: 0;
  /* Remove margin from last item */
}

.addTransportVehicle-label {
  font-weight: 500;
  font-size: 15px;
  color: #495057;
  /* Neutral dark color */
  margin-bottom: 5px;
}

.addTransportVehicle-input,
.addTransportVehicle-select {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.addTransportVehicle-input:focus,
.addTransportVehicle-select:focus {
  border-color: #80bdff;
}

.addTransportVehicle-footer {
  display: flex;
  justify-content: flex-end;
  padding: 6px;
  background-color: #e9ecef;
}

.addTransportVehicle-submitButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addTransportVehicle-submitButton:hover {
  background-color: #218838;
}

.modal .modal-content .modal-footer {
  padding: 3px;
}

.Transport_Student_content {
  background-color: white;
}

.Transport_Student_content-header {
  padding: 16px;
}

.Transport_Student_leftside-content-header {
  display: flex;
  align-items: center;
}

.Transport_Student_breadcrumbs {
  list-style: none;
  padding: 0;
}

.Transport_Student_breadcrumbs li {
  display: inline;
  margin-right: 10px;
  color: #666;
}

.Transport_Student_bg_page {
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.Transport_Student_info-box {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  max-width: 300px;
  margin: 20px 0;
}

.Transport_Student_icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #4caf50;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 16px;
}

.Transport_Student_icon img {
  width: 36px;
  height: 36px;
}

.Transport_Student_details {
  flex-grow: 1;
}

.Transport_Student_name {
  color: #007bff;
  margin: 0;
  font-size: 18px;
}

.Transport_Student_class {
  color: #666;
  font-size: 14px;
  margin: 4px 0;
}

.Transport_Student_info {
  margin: 4px 0;
  font-size: 14px;
  color: #666;
}

/* Container for all student transport cards */
.student-transport-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
}

/* Styling for each transport card */
.student-transport-card {
  display: flex;
  flex-direction: row;
  /* Align items horizontally */
  align-items: center;
  /* Center align items vertically */
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 45%;
  /* Adjust width to fit two cards per row */
  margin-bottom: 20px;
  position: relative;
}

/* Container for student info with image and class */
.student-transport-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
  /* Space between icon/class and student details */
}

/* Styling for the transport icon with class name */
.student-transport-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #33cc33;
  /* Background color for the circle */
  border-radius: 50%;
  padding: 5px;
  height: auto;
}

.student-transport-icon img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.student-transport-class {
  background-color: #e0e0e0;
  border-radius: 50%;
  /* padding: 5px; */
  width: 100px;
  text-align: center;
  color: #007bff;
  font-weight: bold;
  /* font-size: 14px; */
  position: absolute;
  bottom: -6px;
}

/* Styling for student details */
.student-transport-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 50px;
}

.student-transport-info-item {
  margin: 5px 0;
  color: #666;
}

.student-transport-info-item span {
  font-weight: bold;
  color: #333;
}

/* Student_TransportDetails */

.Student_TransportDetails-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f4f4f4;
  /* Light gray background */
  border-radius: 15px;
}

.Student_TransportDetails-student-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  background-color: #002366;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.Student_TransportDetails-profile-pic {
  width: 80px;
  height: 80px;
  background-color: #b3e5fc;
  border-radius: 50%;
}

.Student_TransportDetails-info {
  flex-grow: 2;
  padding-left: 20px;
  color: #e0f7fa;
}

.Student_TransportDetails-roll-info {
  text-align: right;
  color: #0288d1;
}

.Student_TransportDetails-roll-number {
  font-size: 30px;
  font-weight: bold;
}

.Transport_Student_Details_content {
  background-color: #002366;
  /* Royal Blue */
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  font-family: "Segoe UI", sans-serif;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.Transport_Student_Details_content_header {
  margin-bottom: 20px;
}

.Transport_Student_Details_leftside_content_header {
  padding: 0;
}

.Transport_Student_Details_breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Transport_Student_Details_breadcrumbs li {
  display: inline;
  margin-right: 5px;
}

.Transport_Student_Details_breadcrumbs a {
  color: #00bfff;
  text-decoration: none;
}

.Transport_Student_Details_bg_page_grey_rounded {
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.Transport_Student_Details_container {
  padding: 20px;
}

.Transport_Student_Details_student_details {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: #003366;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.Transport_Student_Details_profile_pic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ddd;
  background-color: #f0f0f0;
  margin: 10px;
}

.Transport_Student_Details_profile_pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.Transport_Student_Details_info {
  flex: 1 1;
}

.Transport_Student_Details_roll_info {
  margin-top: 10px;
}

.Transport_Student_Details_roll_number {
  font-size: 18px;
  color: #ffd700;
}

.Transport_Student_Details_group {
  font-size: 16px;
}

.Transport_Student_Details_transport_student_details {
  background-color: white;
  /* Dark Blue */
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Transport_Student_Details_transport_service {
  margin-top: 20px;
}

.Transport_Student_Details_vehicle_info {
  background-color: #00030c;
  /* Darker background for better contrast */
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Adding shadow for a subtle 3D effect */
  display: flex;
  align-items: center;
  justify-content: center;
}

.Hostel_Student_Details_vehicle_info {
  background-color: #00030c;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Transport_Student_Details_info_row {
  display: flex;
  align-items: flex-start;
  /* Align items to the start */
}

.Transport_Student_Details_info_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 20px;
  /* Padding for spacing */
}

.Transport_Student_Details_info_column:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  /* Adjust based on padding */
  width: 1px;
  background-color: #87ceeb;
  /* Light Sky Blue */
}

.Transport_Student_Details_label {
  font-size: 16px;
  /* Font size for labels */
  color: #ffd700;
  /* Deep Sky Blue for better contrast */
  font-weight: bold;
  margin-bottom: 5px;
  /* Space below the label */
}

.Transport_Student_Details_value {
  font-size: 16px;
  /* Font size for values */
  color: #f0f0f0;
  /* Lighter color for better readability */
  margin-bottom: 10px;
  /* Space below the value */
}

.Transport_Student_Details_student_name {
  font-size: 18px;
  /* Slightly larger font size for emphasis */
  color: #fff;
  /* Gold */
  font-weight: bold;
}

.Transport_Student_Details_month_details_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  grid-gap: 15px;
  gap: 15px;
}

.Transport_Student_Details_month_detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border-radius: 10px;
  background-color: #bbdefb;
  /* Light blue */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #333;
  width: 48%;
  /* Two boxes side by side with a small gap */
  text-align: left;
  transition: transform 0.3s, box-shadow 0.3s;
}

.Transport_Student_Details_month_detail:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.Transport_Student_Details_month_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: black;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  position: relative;
}

.icons {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}

.icons i {
  cursor: pointer;
  font-size: 1.3rem;
  color: #1e88e5;
}

.icons i:hover {
  color: #1565c0;
}

.Transport_Student_Details_month_info span {
  flex: 1 1;
}

.Transport_Student_Details_month_info p {
  flex: 1 1;
  text-align: center;
}

.Transport_Student_Details_fee_info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.Transport_Student_Details_fee_info p {
  margin: 0;
}

.Transport_Student_Details_remove_button {
  width: 100%;
  background-color: #0288d1;
  /* Medium blue */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.Transport_Student_Details_remove_button:hover {
  background-color: #0277bd;
  /* Slightly darker blue on hover */
}

.Transport_Student_Details_green {
  background-color: #e8f5e9;
  /* Light green background */
  color: #388e3c;
  /* Dark green text */
  border: 1px solid #2e7d32;
  /* Dark green border */
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 14px;
}

.Transport_Student_Details_red {
  background-color: rgb(227, 82, 82);
  color: white;
  border: 1px solid #ff0389;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 14px;
}

.Transport_Student_Details_transport_service h3 {
  font-size: 22px;
  margin: 10px;
  text-align: center;
  font-weight: bolder;
  color: #00bfff;
}

.Transport_Student_Details_transport_student_details h3 {
  font-size: 22px;
  margin: 10px;
  text-align: left;
  font-weight: bolder;
  color: #00bfff;
}

/* add-transport-student */

.AddTransportStudent {
  max-width: 900px;
  margin: auto;
  padding: 30px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.AddTransportStudent h2 {
  margin-bottom: 30px;
  color: #1e88e5;
  font-size: 2.2rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.AddTransportStudent .student-info-box {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 12px;
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.AddTransportStudent .student-info {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  text-align: center;
  /* Center align text */
}

.AddTransportStudent .student-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #1e88e5;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.AddTransportStudent .student-details {
  line-height: 1.6;
}

.AddTransportStudent .student-details h3 {
  color: #1e88e5;
  margin: 0;
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
}

.AddTransportStudent .student-details p {
  margin: 5px 0;
  color: #666;
  font-size: 1.1rem;
  font-family: "Roboto", sans-serif;
}

.AddTransportStudent .form-box {
  padding: 20px;
  border-radius: 12px;
  background: #f4f6f8;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.AddTransportStudent .form-row {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
}

.AddTransportStudent .form-group {
  flex: 1 1;
}

.AddTransportStudent .form-group label {
  display: block;
  font-weight: 600;
  color: #333;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
}

.AddTransportStudent .form-control {
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 1rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.AddTransportStudent .form-control:focus {
  border-color: #1e88e5;
  box-shadow: 0 0 0 0.2rem rgba(30, 136, 229, 0.25);
}

.AddTransportStudent .btn-primary {
  background-color: #1e88e5;
  border-color: #1e88e5;
  color: #fff;
  padding: 14px 20px;
  border-radius: 8px;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 100%;
  display: block;
}

.AddTransportStudent .btn-primary:hover {
  background-color: #1565c0;
  border-color: #1565c0;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
}

.AddTransportStudent .months {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  /* Center the months */
  margin-bottom: 20px;
}

.AddTransportStudent .month-box:hover {
  background: #1565c0;
  transform: scale(1.05);
}

.AddTransportStudent .form-group {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.AddTransportStudent .form-group .form-control {
  flex: 2 1;
  width: auto;
}

.AddTransportStudent .form-row {
  margin-bottom: 20px;
}

.AddTransportStudent_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ServiceMonth {
  color: black;
  text-align: center;
  font-size: xx-large;
}

.AddTransportStudent .month-box {
  background: #1e88e5;
  color: #fff;
  border-radius: 8px;
  padding: 12px 18px;
  text-align: center;
  cursor: pointer;
  width: 70px;
  font-size: 1.1rem;
  font-family: "Roboto", sans-serif;
  transition: background 0.3s, transform 0.3s, border 0.3s;
}

.AddTransportStudent .month-box:hover {
  background: #1565c0;
  transform: scale(1.05);
}

.AddTransportStudent .month-box.selected {
  background: #ffca00;
  /* Color for selected month */
  color: #000;
  /* Text color for selected month */
  border: 2px solid #003366;
  /* Dark border for selected month */
}

.route-box {
  margin: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Enhanced shadow for 3D effect */
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.route-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.route-info {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.route-info > div {
  font-size: 16px;
  transition: color 0.3s;
}

.route-box:hover .route-info > div {
  color: #333;
}

.details-btn {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.details-btn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
}

.page-title::after {
  content: "";
  display: block;
  width: 25%;
  height: 3px;
  background-color: #ffca00;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.TransportAddNewRoute .content1 {
  padding: 20px;
}

/* Container for fields */
.TransportAddNewRoute .fields-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
}

/* Box for each field */
.TransportAddNewRoute .field-box {
  flex: 1 1;
  min-width: 150px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.TransportAddNewRoute .field-box label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.TransportAddNewRoute .field-box input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.TransportAddNewRoute .mar_top1 {
  margin-top: 20px;
}

.TransportAddNewRoute .btn-success {
  font-size: 15px;
  width: 100%;
  padding: 8px;
}

/* TransportRoutesDetails.css */
/* Container for route student details */
.route-student-details-container {
  padding: 20px;
  background-color: whitesmoke;
}

.bg-page-grey-rounded-route-student-details {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.pickup-points {
  margin-bottom: 20px;
  border-bottom: 2px solid green;
}

.pickup-points h2 {
  margin-bottom: 15px;
  color: #333;
}

.pickup-points-list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.pickup-point {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(12.5% - 10px);
  background-color: #ffca00;
  color: #000;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 10px;
  cursor: pointer;
}

.pickup-point:hover {
  background-color: #e5b800;
}

/* Student details section styling */
.page-title-route-student-details {
  margin-top: 20px;
  margin-bottom: 15px;
  color: #333;
  font-size: 1.5em;
}

/* Container for the list of students */
.students-list1 {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.student-box-route-student-details {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Flex container for student info items */
.student-info-route-student-details {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  gap: 10px;
}

/* Styling for each info item */
.student-info-item,
.student-info-item1 {
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  color: #333;
}

.student-info-item1 {
  font-weight: bold;
}

/* Royal Dark Blue Box */
.royal-dark-blue-box {
  background-color: #0033a0;
  color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
}

/* Flex container for route info items */
.route-info {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
}

/* Each route info item styling */
.route-info-item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(20% - 10px);
  background-color: #004080;
  padding: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Label and value styling */
.route-info-label {
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 5px;
}

.route-info-value {
  color: #ffffff;
}

.months-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.month-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.month-circle {
  width: 60px;
  /* Smaller circle size */
  height: 60px;
  /* Smaller circle size */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.month-circle.selected {
  background-color: #ffca00;
  color: #000;
}

.month-circle:hover {
  background-color: #ffc107;
}

.month-year-text {
  margin-top: 5px;
  color: #777;
  /* Different color for year-month text */
  font-size: 14px;
  font-weight: bold;
}

/* Base styles for the container */
.student_Attendance-container {
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.student_Attendance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 8px;
  margin-bottom: 20px;
}

.student_Attendance-header h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.student_Attendance-header button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.student_Attendance-header button:hover {
  background-color: #0056b3;
}

.student_Attendance-content {
  padding: 10px;
}

.student_Attendance-class-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.student_Attendance-date-switch {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  /* Space between date and switch button */
}

/* Styles for the date info section */
.student_Attendance-date-info {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  /* Space between date elements */
  align-items: center;
}

.student_Attendance-date-info span {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

/* Styles for the switch button */
.student_Attendance-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  /* Width of the switch button */
  height: 20px;
  /* Height of the switch button */
}

.student_Attendance-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.student_Attendance-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  transition: 0.4s;
}

.student_Attendance-slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.student_Attendance-switch input:checked + .student_Attendance-slider {
  background-color: #007bff;
}

.student_Attendance-switch input:checked + .student_Attendance-slider:before {
  transform: translateX(20px);
}

/* Student list styles */
.student_Attendance-student-list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.student_Attendance-student-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 10px);
  max-width: calc(50% - 10px);
  box-sizing: border-box;
  min-height: 80px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid gray;
}

.student_Attendance-student-card.absent {
  background-color: #ffe9e9;
  /* Light red for absent */
}

.student_Attendance-student-info {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}

.student_Attendance-student-info img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.student_Attendance-student-details {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-right: 20px;
}

.student_Attendance-student-details h3 {
  margin: 0;
  font-size: 14px;
  color: #333;
  overflow-wrap: break-word;
}

.student_Attendance-student-details p {
  margin: 0;
  font-size: 12px;
  color: gray;
}

.student_Attendance-student-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 60px;
}

.student_Attendance-roll-number {
  font-weight: 600;
  font-size: 12px;
  color: #333;
  margin-bottom: 5px;
}

.student_Attendance-status-circle {
  display: inline-block;
  background-color: #007bff;
  /* Default color changed to blue */
  color: white;
  font-size: 26px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
}

.student_Attendance-status-circle.absent {
  background-color: red;
  color: white;
}

/* Summary section */
.student_Attendance-attendance-summary {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  background-color: #f1f1f1;
  border-radius: 8px;
  margin-top: 20px;
}

.student_Attendance-summary-item1 {
  font-size: 26px;
  color: #007bff;
  font-weight: bolder;
  padding: 0 10px;
}

.student_Attendance-summary-item {
  font-size: 14px;
  color: #555;
  font-weight: 600;
  padding: 0 10px;
}

.student_Attendance-summary-item:nth-child(1) {
  color: #007bff;
}

.student_Attendance-summary-item:nth-child(2) {
  color: #28a745;
}

.student_Attendance-summary-item:nth-child(3) {
  color: #ffc107;
}

.student_Attendance-summary-item:nth-child(4) {
  color: #dc3545;
}

/* Submit button */
.student_Attendance-submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 15px;
  cursor: pointer;
}

.student_Attendance-submit-button:hover {
  background-color: #0056b3;
}

/* Header Styling */
.after_student_Attendance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #4169e1;
  /* Royal Blue */
}

/* Existing Content Styling */
.after_student_Attendance-content {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

.after_student_Attendance-content p {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #4169e1;
  /* Border in Royal Blue */
  color: #ff9933;
  /* Text in Kesariya */
}

/* Highlighted Data */
.highlighted-data {
  color: #ff9933;
  /* Kesariya */
  font-weight: bold;
}

/* View Button Styling */
.student_Attendance-view-button {
  padding: 5px 10px;
  background-color: #4169e1;
  /* Royal Blue */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.student_Attendance-view-button:hover {
  background-color: #00008b;
  /* Dark Blue */
}

/* New Summary Box Styling */
.student_Attendance-summary-box {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  padding: 5px;
  background-color: #00008b;
  /* Dark Blue */
  border-radius: 5px;
  margin-top: 20px;
}

.student_Attendance_summary_item {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f7f7f7;
  border: 1px solid #4169e1;
  /* Border in Royal Blue */
  border-radius: 5px;
  color: #ff9933;
  /* Text in Kesariya */
}

/* admin-customize.css */
.after_Attendance_student {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.filter-button {
  flex: 1 1;
  margin: 0 10px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  background-color: #c1c1c1;
  /* Default gray background color */
  transition: background-color 0.3s;
}

.filter-button.present {
  background-color: #5a9bd3;
  /* Royal Blue */
}

.filter-button.absent {
  background-color: #f8b400;
  /* Kesariya (orange) */
}

.filter-button.leave {
  background-color: #ff9800;
  /* Light orange for leave */
}

.filter-button.all {
  background-color: #4caf50;
  /* Green for All */
}

.filter-button.active {
  background-color: #0033a0;
  /* Dark Royal Blue for active state */
}

.AssignHomework-container {
  max-width: 100%;
  margin: 0 auto;
}

.AssignHomework-header {
  margin-bottom: 20px;
}

.AssignHomework-header-content {
  display: flex;
  justify-content: space-between;
}

.AssignHomework-class-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #0033cc;
  border-radius: 8px;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
}

.AssignHomework-class-date .class-item,
.AssignHomework-class-date .date-item {
  flex: 1 1;
  text-align: center;
  border-radius: 4px;
}

.AssignHomework-class-date .date-item {
  background: #ff8c00;
}

.AssignHomework-add-box {
  margin-bottom: 20px;
  background: #e6f0ff;
  border: 1px solid #cce0ff;
  border-radius: 8px;
  padding: 15px;
}

.AssignHomework-add-box label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #0033cc;
}

.AssignHomework-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #cce0ff;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fff;
}

.AssignHomework-image-box {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #cce0ff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.AssignHomework-image-input {
  padding: 5px;
  border: 1px solid #cce0ff;
  border-radius: 4px;
  margin-right: 10px;
  width: auto;
}

.AssignHomework-add-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #0033cc;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.AssignHomework-add-button span {
  margin-left: 5px;
}

.AssignHomework-add-button:hover {
  background: #002299;
}

.AssignHomework-assigned-box {
  margin-top: 20px;
  background: #f0f8ff;
  border: 1px solid #cce0ff;
  border-radius: 8px;
  padding: 15px;
}

.AssignHomework-assigned-box h3 {
  margin: 0;
  padding: 10px;
  background: #0033cc;
  color: #fff;
  border-radius: 4px;
  text-align: center;
}

.AssignHomework-homework-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.AssignHomework-homework-item:hover {
  background: #f5faff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.AssignHomework-info {
  display: flex;
  align-items: center;
}

.AssignHomework-number {
  font-weight: bold;
  margin-right: 15px;
  color: #0033cc;
}

.AssignHomework-description {
  flex: 1 1;
  color: #333;
}

.AssignHomework-actions {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.AssignHomework-icon {
  cursor: pointer;
  color: #0033cc;
  font-size: 16px;
}

.AssignHomework-icon:hover {
  color: #002299;
}

.AssignHomework-not-assigned-box {
  text-align: center;
  padding: 8px;
  border: 1px solid #f59d9d;
  border-radius: 4px;
  background: #f3adad;
  color: darkblue;
  font-weight: bold;
}

.AssignHomework-images-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.AssignHomework-image-item {
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AssignHomework-image-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.AssignHomework-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.AssignHomework-image-full {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}

/* holiday.css */
.holiday-container {
  padding: 20px;
  background-color: #f9f9f9;
  /* Light background color for contrast */
}

.holiday-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #ffffff;
  /* White background for the header */
  border: 1px solid #ddd;
  /* Light border for the header box */
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Shadow effect */
  width: 100%;
  /* Full width */
}

.holiday-title {
  font-size: 24px;
  color: #333;
  /* Dark text color */
}

.holiday-btn-add {
  background-color: #f39c12;
  /* Kesariya background for the button */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  /* Rounded corners */
  transition: background-color 0.3s;
  /* Smooth transition */
}

.holiday-btn-add:hover {
  background-color: #e67e22;
  /* Darker kesariya on hover */
}

.holiday-card-container {
  display: flex;
  flex-direction: column;
  /* Stack cards vertically */
  grid-gap: 20px;
  gap: 20px;
  /* Space between cards */
}

.holiday-card {
  background-color: #ffffff;
  /* White background for the card */
  border: 3px solid #ddd;
  /* Light border for the card */
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Shadow effect */
  padding: 15px;
  width: 100%;
  /* Full width of the container */
  overflow: hidden;
}

.holiday-card-header {
  font-size: 18px;
  font-weight: bold;
  color: #3498db;
  /* Royal blue color for the header */
  margin-bottom: 10px;
}

.holiday-card-content {
  font-size: 16px;
  color: #333;
}

.holiday-card-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  /* Allow items to wrap if necessary */
}

.holiday-card-item {
  flex: 1 1;
  /* Allow items to take equal space */
  margin-right: 10px;
  /* Space between items */
  word-break: break-word;
  /* Break long words to prevent overflow */
}

.holiday-card-item:last-child {
  margin-right: 0;
  /* No margin for the last item */
}

.holiday-card-item.wide {
  flex: 2 1;
  /* Double space for the 'Announced Date' item */
}

.holiday-card-item.remark {
  flex-basis: 100%;
  /* Full width for the remark section */
  margin-top: 10px;
  /* Space above the remark */
  margin-bottom: 0;
  /* No bottom margin */
}

.holiday-no-data {
  text-align: center;
  color: #999;
  padding: 20px;
}

/* Add this CSS to your stylesheet */
.holiday-card-content {
  position: relative;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
}

.delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #d9534f;
  /* Red color for delete icon */
  cursor: pointer;
  font-size: 16px;
}

.delete-btn:hover {
  color: #c9302c;
  /* Darker red for hover effect */
}

.holiday-card-header {
  font-size: 16px;
  font-weight: bold;
  color: royalblue;
  /* Customize the color for header */
}

.holiday-card-item {
  margin-bottom: 10px;
}

.holiday-card-item.wide {
  flex: 1 1;
}

.holiday-card-item.remark {
  margin-top: 10px;
}

.addholiday .form-container {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.addholiday .form-box,
.addholiday .form-box-secondary {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  /* Ensures content stays within the box */
  box-sizing: border-box;
}

.addholiday .form-row {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.addholiday .form-group {
  flex: 1 1;
  min-width: 220px;
  margin-bottom: 15px;
}

.addholiday .form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.addholiday .form-control {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.addholiday .form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

.addholiday textarea.form-control {
  resize: vertical;
  box-sizing: border-box;
  /* Ensures padding is included in the total width */
}

.addholiday .submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addholiday .submit-button:hover {
  background-color: #0056b3;
}

.addholiday .form-footer {
  display: flex;
  justify-content: center;
}

.addholiday .form-container {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.addholiday .form-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  box-sizing: border-box;
}

.addholiday .form-row {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.addholiday .form-group {
  flex: 1 1;
  min-width: 220px;
  margin-bottom: 15px;
}

.addholiday .form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.addholiday .form-control {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.addholiday .form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

.addholiday textarea.form-control {
  resize: vertical;
}

.addholiday .submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addholiday .submit-button:hover {
  background-color: #0056b3;
}

.addholiday .form-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.leave-management {
  padding: 20px;
  background-color: #f9f9f9;
  /* Light background for the section */
}

.leave-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}

.leave-card.pending {
  border-left: 5px solid #ff9800;
  /* Orange color for pending */
}

.leave-card.approved {
  border-left: 5px solid #4caf50;
  /* Green color for approved */
}

.leave-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background-color: #f1f1f1;
  /* Light grey background for header */
  padding: 10px;
  border-radius: 5px;
}

.leave-date {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.leave-status {
  display: flex;
  align-items: center;
}

.leave-status-icon {
  font-size: 16px;
  margin-right: 8px;
}

.leave-status-text {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.leave-application-text {
  font-size: 13px;
  line-height: 1.6;
  color: #555;
  text-align: justify;
  word-wrap: break-word;
  margin-top: 10px;
}

/* Additional styling for links and icons */
.leave-status-icon i {
  color: #007bff;
  /* Default color for icons */
}

.profile-box {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-image {
  flex-shrink: 0;
  margin-right: 20px;
  width: 150px;
  /* Adjust the size as needed */
  height: 150px;
  /* Adjust the size as needed */
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the container without stretching */
}

.profile-details {
  flex: 1 1;
}

.staff-basic-info {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  /* Adjust spacing between items */
}

.info-item {
  display: flex;
  align-items: center;
  flex: 1 1 30%;
  /* Adjust as needed for responsive layout */
}

.label_myprofile {
  font-size: larger;
  color: #333;
  font-weight: bold;
  margin-right: 5px;
}

.data {
  color: #666;
}

.staff-info-row {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  /* Adjust spacing between items */
  margin-top: 20px;
}

.staff-info-row p {
  margin: 0;
  flex: 1 1 30%;
  /* Adjust percentage as needed */
}

.address-full {
  margin-top: 20px;
  width: 100%;
}

.change-password-btn {
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
}

.static-class-box {
  border: 1px solid #ddd;
  padding: 10px;
  /* Adjusted padding */
  margin: 10px 0;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.class-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Space between elements */
  font-size: 18px;
  font-weight: bold;
}

.static-name {
  color: #003366;
  /* Dark Blue */
}

.student-count {
  color: #ff9f00;
  /* Kesariya (Saffron) */
}

.static-header-buttons {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  /* Space between buttons */
}

.static-header-buttons .btn {
  padding: 8px 15px;
  /* Adjusted padding */
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.btn-primary {
  background-color: #003366;
  /* Dark Blue */
}

.btn-secondary {
  background-color: #6c757d;
  /* Default color for secondary */
}

.btn-warning {
  background-color: #ff9f00;
  /* Kesariya (Saffron) */
}

/* Circle Layout Styles */
.circle-container {
  display: flex;
  justify-content: space-between;
  /* Space between circles */
  margin-top: 20px;
  /* Space from header */
}

.circle-item {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--highlight_color2);
  border: 3px solid gray !important;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0 5px;
  /* Space between circles */
}

.circle-info {
  text-align: center;
  font-size: 12px;
  color: #ff9f00;
  /* Kesariya (Saffron) */
  margin-top: 5px;
  /* Space below each circle */
}

.FeeReceiptSettings-container {
  padding: 20px;
}

.FeeReceiptSettings-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.FeeReceiptSettings-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.FeeReceiptSettings-box {
  flex: 1 1;
  padding: 20px;
  margin: 0 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FeeReceiptSettings-label {
  font-size: 16px;
  color: #555;
}

.FeeReceiptSettings-checkbox {
  width: 20px;
  height: 20px;
}

.DepartmentSettings-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

/* Styling the main box */
.DepartmentSettings-box {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  /* To add space between multiple boxes */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}

/* Each section (Department/Head) styling */
.DepartmentSettings-content {
  display: flex;
  align-items: center;
}

/* Label styling */
.DepartmentSettings-label {
  font-weight: bold;
  font-size: 16px;
  margin-right: 10px;
}

/* Value styling */
.DepartmentSettings-value {
  font-size: 16px;
  color: #333;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.PromoteStudent-student-checkbox {
  align-items: center;
}

.PromoteStudent-student-checkbox input {
  margin-right: 5px;
  transform: scale(1.2);
  /* Enlarge the checkbox */
}

.AdmissionLink-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  background-color: #4169e1;
  /* Royal Blue */
  color: #ff9933;
  /* Kesariya */
  font-family: "Questa", serif;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.AdmissionLink-info-section {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 60px;
  gap: 60px;
  /* Increased gap between logo and school information */
  margin-bottom: 20px;
}

.AdmissionLink-school-logo img {
  height: 100px;
  /* Fixed height for the logo */
  width: auto;
  border-radius: 8px;
}

.AdmissionLink-school-info h2 {
  font-size: 26px;
  margin: 0;
  font-family: "Georgia", serif;
  /* Sophisticated font */
  font-weight: bold;
  color: #ff9933;
}

.AdmissionLink-school-info p {
  margin: 5px 0;
  font-size: 15px;
  color: #ffffff;
  /* White color for better contrast */
}

.AdmissionLink-separator {
  width: 100%;
  height: 2px;
  background-color: #ff9933;
  /* Kesariya line color */
  margin: 20px auto;
}

.AdmissionLink-admission-open h3 {
  color: #ff9933;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.AdmissionLink-links {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 20px;
}

.AdmissionLink-button,
.AdmissionLink-share-button {
  padding: 8px 20px;
  /* Reduced button size */
  font-size: 14px;
  /* Smaller font size for buttons */
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.AdmissionLink-button {
  background-color: #ff9933;
  color: #fff;
  text-decoration: none;
  border: none;
}

.AdmissionLink-button:hover {
  background-color: #ff7f24;
  /* Darker kesariya on hover */
}

.AdmissionLink-share-button {
  background-color: #fff;
  color: #4169e1;
  border: 2px solid #ff9933;
}

.AdmissionLink-share-button:hover {
  background-color: #ff9933;
  color: #fff;
}

.AdmissionLink-student-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
}

.AdmissionLink-student-card-list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;
  gap: 16px;
  padding: 20px;
  justify-content: center;
  max-width: 800px;
}

.AdmissionLink-student-card {
  border: 1px solid #f00;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px;
  width: 220px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.AdmissionLink-student-card-header {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.AdmissionLink-student-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007bff;
}

.AdmissionLink-student-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: royalblue;
}

.AdmissionLink-student-info {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #333;
}

.AdmissionLink-student-actions {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px;
}

.AdmissionLink-certificate-button,
.AdmissionLink-delete-button {
  flex: 1 1;
  padding: 8px 12px;
  font-size: 0.9rem;
  border: none;
  border-radius: 4px;
  color: #fff;
}

.AdmissionLink-certificate-button {
  background-color: #007bff;
}

.AdmissionLink-delete-button {
  background-color: #007bff;
}

.AdmissionLink-add-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: auto;
  align-self: center;
}

.login-boxes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.login-box {
  padding: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 80px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.login-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 2rem;
  color: #ffca00;
  margin-bottom: 10px;
}

.login-boxes p {
  font-size: 1.3rem;
  font-weight: bold;
  color: #333;
}

.IssueAccessories-container {
  font-family: Arial, sans-serif;
  width: 100%;
  margin: auto;
  text-align: center;
}

.IssueAccessories-header-section {
  background-color: #00a6eb;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.IssueAccessories-student-info {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #e0f7fa;
  margin: 10px 0;
  border-radius: 5px;
}

.IssueAccessories-profile-pic {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.IssueAccessories-profile-pic img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #007bff;
}

.IssueAccessories-student-details {
  flex: 1 1;
  text-align: left;
  margin-left: 10px;
}

.IssueAccessories-roll-info {
  text-align: center;
  border: 1px solid #00a6eb;
  padding: 5px;
  border-radius: 5px;
}

.IssueAccessories-accessories-added {
  color: #ffea00;
  background-color: #00a6eb;
  padding: 5px;
  font-size: 14px;
  margin: 5px 0;
}

.IssueAccessories-date-section {
  font-weight: bold;
  color: #333;
  background-color: #f0f0f0;
  padding: 3px;
  margin-bottom: 3px;
  border-radius: 5px;
  font-size: 1.2em;
}

.IssueAccessories-table-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  padding: 8px;
}

.IssueAccessories-table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  grid-gap: 0.2px;
  gap: 0.2px;
}

.IssueAccessories-table-header {
  background-color: #f2a854;
  color: #fff;
  font-weight: bold;
  padding: 3px;
}

.IssueAccessories-table-cell {
  padding: 3px;
  background-color: #fafafa;
}

.IssueAccessories-payment-summary {
  display: flex;
  grid-gap: 1px;
  gap: 1px;
  margin: 3px 0;
  justify-content: space-between;
}

.IssueAccessories-summary-item {
  flex: 1 1;
  padding: 3px;
  border-radius: 5px;
  background-color: #e0e0e0;
  font-weight: bold;
}

.IssueAccessories-paid {
  background-color: #4caf50;
  color: white;
}

.IssueAccessories-due {
  background-color: #f44336;
  color: white;
}

.IssueAccessories-payment-summary {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  margin: 10px 0;
}

.IssueAccessories-summary-item {
  flex: 1 1;
  padding: 8px;
  border-radius: 5px;
  background-color: #e0e0e0;
  font-weight: bold;
}

.IssueAccessories-paid {
  background-color: #4caf50;
  color: white;
}

.IssueAccessories-due {
  background-color: #f44336;
  color: white;
}

.IssueAccessories-select-student-button,
.IssueAccessories-add-particulars-button,
.IssueAccessories-submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s ease;
}

.IssueAccessories-add-particulars-button {
  background-color: #f2a854;
}

.IssueAccessories-submit-button {
  background-color: #4caf50;
}

.IssueAccessories-select-student-button:hover,
.IssueAccessories-add-particulars-button:hover,
.IssueAccessories-submit-button:hover {
  background-color: #0056b3;
}

.IssueAccessories-add-particulars-button {
  background-color: #f2a854;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  width: auto;
  text-align: center;
  margin: 10px 0;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.IssueAccessories-add-particulars-button .IssueAccessories-arrow {
  margin-left: 5px;
  font-size: 18px;
}

.IssueAccessories-add-particulars-button:hover {
  background-color: #e68932;
}

/* Container */
.AddParticulars_container {
  max-width: 350px;
  margin: auto;
  font-family: Arial, sans-serif;
  color: #333;
}

/* Header */
.AddParticulars_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00aeef;
  color: #fff;
  padding: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.AddParticulars_header h2 {
  margin: 0;
  font-size: 1.1em;
}

.close-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 1em;
  cursor: pointer;
}

/* Category Buttons */
.category-buttons {
  display: flex;
  justify-content: space-around;
  background-color: #00aeef;
  padding: 8px 0;
}

.category-btn {
  padding: 6px 8px;
  background-color: #fff;
  color: #00aeef;
  border: 2px solid #00aeef;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8em;
}

.category-btn.active {
  background-color: #ffdc00;
  color: #000;
}

/* Product Card */
.product-card {
  display: flex;
  background-color: #e6f5ff;
  border: 1px solid #00aeef;
  border-radius: 8px;
  margin: px;
  padding: 8px;
  font-size: 0.85em;
}

.stock-info {
  background-color: #00aeef;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  margin-right: 8px;
  width: 80px;
}

.stock-info span {
  font-size: 1.2em;
  font-weight: bold;
}

.stock-info h3 {
  margin: 4px 0 0;
  font-size: 1.2em;
  font-weight: bold;
}

.product-details {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-details h4 {
  font-size: 1.2em;
  font-weight: bold;
}

.product-details p {
  font-size: 1em;
  font-weight: bold;
}

.price {
  font-size: 1.1em;
  font-weight: bold;
}

.add-btn {
  padding: 4px 8px;
  background-color: #00aeef;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
}

/* Done Button */
.done-btn {
  width: 100%;
  padding: 12px;
  background-color: #00aeef;
  color: #fff;
  font-size: 1em;
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
}

.AccessoriesBeneficiary-content {
  background-color: white;
  padding: 8px;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin: 8px auto;
  max-height: 300px;
  overflow-y: auto;
}

.AccessoriesBeneficiary-profileContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 6px;
  border-radius: 6px;
}

.AccessoriesBeneficiary-profile {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.AccessoriesBeneficiary-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.AccessoriesBeneficiary-nameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.AccessoriesBeneficiary-name {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.AccessoriesBeneficiary-guardian {
  font-size: 10px;
  color: #666;
  margin: 0;
}

.AccessoriesBeneficiary-divider {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin: 6px 0;
}

.AccessoriesBeneficiary-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.AccessoriesBeneficiary-box {
  text-align: center;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 7px;
  width: 100px;
  flex: 1 1 100px;
  margin: 4px;
  background-color: #ffca00;
  color: black;
}

.AccessoriesBeneficiary-actions {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

.AccessoriesBeneficiary-button {
  background-color: #ff4d4d;
  color: white;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.AccessoriesBeneficiary-button:hover {
  background-color: #ff3333;
}

.AccessoriesBeneficiary-reduced-width {
  width: 98%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
}

.AccessoriesBeneficiary-info .AccessoriesBeneficiary-amount {
  background-color: #ffca00;
  color: black;
}

.AccessoriesBeneficiary-info .AccessoriesBeneficiary-discount {
  background-color: #4caf50;
  color: white;
}

.AccessoriesBeneficiary-info .AccessoriesBeneficiary-payable {
  background-color: #2196f3;
  color: white;
}

.AccessoriesBeneficiary-info .AccessoriesBeneficiary-paid {
  background-color: #ff5733;
  color: white;
}

.AccessoriesBeneficiary-info .AccessoriesBeneficiary-due {
  background-color: #ff6347;
  color: white;
}

/* Main Container */
.accessories-collection {
  background-color: #f0f0f0;
  padding: 20px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* User Info Section */
.accessories-collection-user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d6eaff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.accessories-collection-profile-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #006699;
}

.accessories-collection-profile-circle {
  background-color: #99d6ff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accessories-collection-profile-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.accessories-collection-profile-id {
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
}

.accessories-collection-user-details {
  font-size: 12px;
  color: #333;
}

.accessories-collection-user-name {
  font-weight: bold;
}

.accessories-collection-roll-info {
  text-align: center;
  color: #006699;
}

.accessories-collection-roll-number {
  background-color: #99d6ff;
  border-radius: 5px;
  padding: 5px;
  font-weight: bold;
}

/* Due Info Section */
.accessories-collection-due-info {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

.accessories-collection-due-item {
  padding: 5px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 10px;
}

/* Checkbox Styling */
.accessories-collection-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 5px;
  border: 2px solid #ff9933;
  /* Kesariya color border */
}

.accessories-collection-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.accessories-collection-checkbox:checked {
  background-color: #ff9933;
  /* Kesariya color */
  border-color: #ff9933;
}

/* Due Amount Container */
.accessories-collection-due-amount-container {
  background-color: #00aaff;
  flex-grow: 1;
  padding: 2px;
  border-radius: 10px;
  color: #fff;
  border: 2px solid #0077cc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accessories-collection-due-amount {
  font-size: 14px;
}

/* Collect Options Section */
.accessories-collection-collect-options {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

.accessories-collection-other-amount {
  font-size: 14px;
  color: #333;
}

.accessories-collection-other-amount-input {
  padding: 5px;
  width: 100px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Collect Button */
.accessories-collection-collect-now {
  background-color: #28a745;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.AccessoriesDetails-profile-container {
  font-family: "Noto Sans SC", sans-serif;
  margin: 10px auto;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 8px;
  /* Adjusted padding */
}

.AccessoriesDetails-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00aaff;
  color: white;
  padding: 5px;
  border-radius: 8px;
}

.AccessoriesDetails-profile-pic {
  position: relative;
  width: 50px;
  /* Adjusted size */
  height: 50px;
  /* Adjusted size */
}

.AccessoriesDetails-profile-pic img {
  width: 100%;
  border-radius: 50%;
}

.AccessoriesDetails-student-id {
  position: absolute;
  bottom: -8px;
  /* Adjusted position */
  background-color: #00aaff;
  color: white;
  padding: 2px 4px;
  font-size: 12px;
  text-align: center;
  border-radius: 4px;
}

.AccessoriesDetails-student-info h2 {
  margin: 0;
  font-size: 14px;
  /* Adjusted font size */
}

.AccessoriesDetails-roll-number {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
}

.AccessoriesDetails-roll-circle {
  background-color: white;
  color: #00aaff;
  border: 2px solid #00aaff;
  border-radius: 50%;
  width: 30px;
  /* Adjusted size */
  height: 30px;
  /* Adjusted size */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  margin: 4px 0;
}

.AccessoriesDetails-amount-info {
  display: flex;
  justify-content: space-around;
  margin: 6px 0;
}

.AccessoriesDetails-amount-card {
  background-color: #00aaff;
  color: white;
  padding: 6px;
  /* Adjusted padding */
  border-radius: 8px;
  text-align: center;
  flex: 1 1;
  margin: 0 4px;
}

.AccessoriesDetails-amount-card-gray {
  background-color: #c0c0c0;
}

.AccessoriesDetails-amount-card-green {
  background-color: green;
}

.AccessoriesDetails-amount-card-red {
  background-color: red;
}

.AccessoriesDetails-details {
  font-family: "Noto Sans SC", sans-serif;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00aaff;
  margin-bottom: 8px;
}

.AccessoriesDetails-details h3 {
  text-align: center;
  color: #00aaff;
  font-size: 14px;
  margin-bottom: 6px;
}

.AccessoriesDetails-date {
  text-align: center;
  background-color: green;
  color: white;
  padding: 7px;
  border-radius: 4px;
}

.AccessoriesDetails-table {
  width: 100%;
  border-collapse: collapse;
  margin: 6px 0;
}

.AccessoriesDetails-table th,
.AccessoriesDetails-table td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: center;
  font-size: 14px;
}

.AccessoriesDetails-table th {
  background-color: #ffa500;
  color: black;
}

.AccessoriesDetails-remove-btn,
.AccessoriesDetails-collect-btn {
  width: 99%;
  padding: 7px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 6px;
  margin: 5px;
}

.AccessoriesDetails-remove-btn {
  background-color: red;
  color: white;
}

.AccessoriesDetails-collect-btn {
  background-color: #00aaff;
  color: white;
}

/* Container styling */
.AddNewHostelStudent-container {
  background-color: #ffd54f;
  width: 90%;
  margin: 20px auto;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  max-width: 800px;
}

/* Header styling */
.AddNewHostelStudent-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f1f1f1;
  border-bottom: 2px solid #ccc;
}

/* Select Student Button styling */
.AddNewHostelStudent-selectStudentButton {
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.AddNewHostelStudent-selectStudentButton:hover {
  background-color: #0056b3;
}

/* Student card styling */
.AddNewHostelStudent-studentCard {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Profile picture styling */
.AddNewHostelStudent-profilePic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ddd;
  margin-right: 15px;
}

.AddNewHostelStudent-profilePic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* Student info styling */
.AddNewHostelStudent-studentInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AddNewHostelStudent-studentInfo h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.AddNewHostelStudent-studentInfo p {
  margin: 5px 0;
  font-size: 1rem;
  color: #555;
}

/* Styling for single-line display */
.AddNewHostelStudent-studentInfo p span {
  margin-right: 10px;
  font-weight: normal;
}

/* Input box styling */
.AddNewHostelStudent-inputBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}

/* Input container styling */
.AddNewHostelStudent-inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

/* Label styling */
.AddNewHostelStudent-inputContainer label {
  font-weight: bold;
  font-size: 1rem;
  width: 40%;
  /* Adjust as needed for label width */
}

/* Input field styling */
.AddNewHostelStudent-inputContainer input {
  width: 55%;
  /* Adjust as needed for input width */
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

/* Input focus styling */
.AddNewHostelStudent-inputContainer input:focus {
  border-color: #ffa000;
}

/* Service month styling */
.AddNewHostelStudent-serviceMonth {
  margin-top: 20px;
}

/* Service month header styling */
.AddNewHostelStudent-serviceMonth h3 {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0 0 10px;
  color: #333;
}

/* Month buttons container styling */
.AddNewHostelStudent-months {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}

/* Month button styling */
.AddNewHostelStudent-month {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  width: 60px;
  height: 60px;
  font-size: 0.8rem;
  color: #757575;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 2px solid transparent;
  transition: 0.3s;
  font-weight: bold;
}

/* Selected month button styling */
.AddNewHostelStudent-month.selected {
  border-color: #ffa000;
  color: #ffa000;
  font-weight: bold;
}

/* Add student button styling */
.AddNewHostelStudent-addButton {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #ffa000;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
}

/* Container for the component */
.AddHostelStudent-container {
  font-family: Arial, sans-serif;
  padding: 10px;
  /* Reduced padding */
  max-width: 800px;
  /* Adjusted max width for a compact look */
  margin: auto;
}

/* Flex container for side-by-side cards */
.AddHostelStudent-card-wrapper {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  /* Reduced gap between cards */
}

/* General styles for student cards */
.AddHostelStudent-student-card {
  border-radius: 8px;
  /* Smaller border radius */
  width: 45%;
  /* Reduced width for a smaller card size */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  /* Slightly smaller shadow */
}

/* Header styling */
.AddHostelStudent-header {
  text-align: center;
  font-size: 16px;
  /* Slightly smaller font size */
  font-weight: bold;
  padding: 8px 0;
  /* Reduced padding */
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Background colors for each status */
.AddHostelStudent-granted-header {
  background-color: #ffd700;
}

.AddHostelStudent-not-granted-header {
  background-color: #2196f3;
}

/* Content styling */
.AddHostelStudent-content {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  /* Reduced padding */
  align-items: center;
}

/* Profile section with image and details */
.AddHostelStudent-profile-section {
  display: flex;
  align-items: center;
}

.AddHostelStudent-profile-image {
  width: 50px;
  /* Smaller image */
  height: 50px;
  /* Smaller image */
  border-radius: 50%;
  margin-right: 8px;
  /* Reduced margin */
  border: 1.5px solid #ddd;
  /* Slightly thinner border */
}

.AddHostelStudent-details {
  line-height: 1.3;
  /* Slightly tighter line-height */
}

.AddHostelStudent-name {
  margin: 0;
  font-size: 13px;
  /* Reduced font size */
}

.AddHostelStudent-parent,
.AddHostelStudent-id {
  margin: 0;
  font-size: 11px;
  /* Reduced font size */
}

/* Roll number and play group section */
.AddHostelStudent-roll-section {
  text-align: center;
}

/* Label for roll number */
.AddHostelStudent-roll-label {
  font-size: 13px;
  /* Slightly smaller font size */
  margin-bottom: 3px;
  /* Reduced margin */
  font-weight: bold;
}

/* Circle for roll number */
.AddHostelStudent-roll-circle {
  background-color: #ffd700;
  border-radius: 50%;
  width: 40px;
  /* Smaller circle */
  height: 40px;
  /* Smaller circle */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px auto;
  /* Reduced margin */
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  /* Slightly smaller font size */
}

.AddHostelStudent-not-granted .AddHostelStudent-roll-circle {
  background-color: #2196f3;
}

/* Play group/class label */
.AddHostelStudent-play-group {
  font-size: 11px;
  /* Smaller font size */
  margin-top: 3px;
  /* Reduced margin */
}

.student-hostel1-card {
  background-color: white;
  border: 2px solid #e3e3e3;
  border-radius: 12px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 48%;
}

.student-hostel1-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  text-decoration: none;
}

.student-hostel1-card a {
  text-decoration: none;
  color: inherit;
}

.student-hostel1-info {
  display: flex;
  align-items: center;
}

.student-hostel1-icon {
  margin-right: 20px;
  position: relative;
  text-align: center;
  min-width: 100px;
}

.student-hostel1-icon img {
  border-radius: 50%;
  border: 3px solid #ffeb3b;
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.student-hostel1-class {
  background-color: #ffeb3b;
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 12px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  display: block;
}

/* Details section */
.student-hostel1-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.student-name {
  font-size: 16px;
  font-weight: bold;
  color: #2196f3;
  /* Blue color */
  margin: 0 0 5px;
  text-decoration: none;
  /* Remove underline on hover */
}

.student-father-name {
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
  text-decoration: none;
  /* Remove underline on hover */
}

.student-hostel1-info-item {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
  text-decoration: none;
  /* Remove underline on hover */
}

.student-hostel1-info-item span {
  font-weight: bold;
}

.uppercase-text {
  text-transform: uppercase;
}

/* Main Container */
.school-profile-container {
  margin: 10px auto;
  padding: 15px;
  background-color: royalblue;
  border-radius: 10px;
  text-align: center;
  font-family: Arial, sans-serif;
  color: white;
}

/* Header with Logo and Info Center-Aligned */
.school-profile-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  grid-gap: 20px;
  gap: 20px; /* Adds gap between logo and info */
}

/* Logo Container */
.school-profile-logo-container {
  position: relative;
}

.school-profile-logo {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 2px solid #ffffff;
}

.school-profile-camera-icon {
  position: absolute;
  top: 0;
  right: -10px;
}

.camera-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

/* School Info */
.school-profile-info {
  text-align: left; /* Align text inside the school info */
}

.school-profile-name {
  font-size: 18px;
  font-weight: bold;
}

.school-profile-slogan {
  font-size: 14px;
  font-style: italic;
  color: #d1e8ff;
  margin-top: 3px;
}

.school-profile-details {
  font-size: 12px;
  margin-top: 5px;
}

/* Metadata Section */
.school-profile-metadata-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: royalblue;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.school-profile-metadata-box {
  flex: 1 1;
  margin-right: 8px;
}

.school-profile-metadata-box:last-child {
  margin-right: 0;
}

.school-profile-metadata-box p {
  margin: 5px 0;
  font-size: 13px;
}

/* Contact Section */
.school-profile-contact-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  grid-gap: 15px;
  gap: 15px;
}

.school-profile-contact-box {
  flex: 1 1;
  background-color: white;
  color: royalblue;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 13px;
}

.school-profile-contact-box a {
  color: royalblue;
  text-decoration: none;
}

.school-profile-contact-box a:hover {
  text-decoration: underline;
}

/* Buttons */
.school-profile-actions {
  margin-top: 15px;
  text-align: center;
}

.school-profile-button {
  padding: 8px 15px;
  background-color: white;
  color: royalblue;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 5px;
}

.school-profile-button:hover {
  background-color: #d1e8ff;
  color: royalblue;
}

.update-school-header {
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  padding: 8px;
  background-color: #0072ff;
  border-radius: 8px;
  margin-bottom: 20px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.update-school-details {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.update-school-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  /* Reduced spacing */
}

.update-school-input-group {
  flex: 1 1;
  margin: 0 8px;
  /* Reduced margin */
}

.update-school-label {
  display: block;
  font-size: 14px;
  /* Made font smaller */
  color: #0072ff;
  margin-bottom: 5px;
  /* Reduced label spacing */
  font-weight: 600;
}

.update-school-input {
  width: 100%;
  padding: 8px;
  /* Reduced padding */
  font-size: 14px;
  border: 1.5px solid #0072ff;
  /* Thinner border */
  border-radius: 5px;
  outline: none;
}

.update-school-input:focus {
  border-color: #005bbb;
  box-shadow: 0 0 4px rgba(0, 114, 255, 0.4);
}

.update-school-submit-button {
  width: 100%;
  padding: 4px;
  font-size: 20px;
  color: #ffffff;
  background-color: #0072ff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.update-school-submit-button:hover {
  background-color: #005bbb;
}

.helpdesk-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
}

.helpdesk-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  color: #333333;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.helpdesk-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.helpdesk-card-content {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  flex: 1 1;
}

.helpdesk-image-container {
  background-color: #e0e0e0;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
}

.helpdesk-image {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.helpdesk-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Name styling */
.helpdesk-name {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0;
}

.helpdesk-languages {
  font-size: 1rem;
  color: #666;
}

.helpdesk-divider {
  border: none;
  height: 1px;
  width: 80%;
  background: #ddd;
  margin: 10px 0;
}

.helpdesk-contact {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 10px;
}

.helpdesk-contact-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.contact-icon {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #333;
}

.contact-number {
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.contact-country-code {
  font-weight: normal;
  font-size: 1rem;
  color: #666;
}

.contact-number-whatsapp {
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.daybook-container {
  border: 2px solid red;
  border-radius: 10px;
  margin: 20px auto;
  text-align: center;
  font-family: Arial, sans-serif;
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.daybook-header {
  font-size: 20px;
  margin: 10px 0;
}

.daybook-income-box {
  background-color: green;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.daybook-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.daybook-circle {
  width: 100px;
  height: 100px;
  background-color: white;
  color: green;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin: 0 5px;
  font-family: Arial, sans-serif;
}

.daybook-amount {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.daybook-text {
  font-size: 12px;
  font-weight: bold;
}

.daybook-view-details-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: white;
  color: green;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.daybook-view-details-button:hover {
  background-color: lightgray;
}

.daybook-arrow {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: red;
  cursor: pointer;
}

.ExpenseManager-container {
  font-family: Arial, sans-serif;
  background-color: #f2f2f2;
  padding: 10px;
}

.ExpenseManager-header-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.ExpenseManager-header {
  font-size: 20px;
  font-weight: bold;
}

.ExpenseManager-cash-out {
  color: red;
  margin-right: 20px;
}

.ExpenseManager-cash-in {
  color: green;
}

.ExpenseManager-entry {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 10px;
}

.ExpenseManager-date-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.ExpenseManager-date {
  font-size: 14px;
  font-weight: bold;
}

.ExpenseManager-icons {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.ExpenseManager-icon {
  font-size: 16px;
  cursor: pointer;
}

.ExpenseManager-edit-icon {
  color: blue;
}

.ExpenseManager-delete-icon {
  color: red;
}

.ExpenseManager-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ExpenseManager-info {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}

.ExpenseManager-box {
  background-color: #e9e9e9;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
}

.ExpenseManager-actions {
  text-align: right;
}

.ExpenseManager-actions1 {
  text-align: right;
  margin-right: 150px;
}

.ExpenseManager-amount {
  font-size: 14px;
  margin-top: 5px;
}

.ExpenseManager-cash-in {
  color: green;
  font-weight: bold;
}

.ExpenseManager-cash-out-section {
  margin-top: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ExpenseManager-cash-out-details {
  display: flex;
  justify-content: flex-start;
  grid-gap: 15px;
  gap: 15px;
}

.ExpenseManager-cash-out {
  color: red;
  font-size: 16px;
  font-weight: bold;
}

.ExpenseManager-footer {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.ExpenseManager-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.ExpenseManager-button.ExpenseManager-cash-in {
  background-color: green;
  color: white;
}

.ExpenseManager-button.ExpenseManager-cash-out {
  background-color: red;
  color: white;
}

.staff_profile_heading {
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
  color: #ffffff;
  background: linear-gradient(90deg, #1976d2, #2196f3);
  /* Blue gradient */
  padding: 10px;
  border-radius: 8px;
  text-transform: uppercase;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* List Container */
.staff_profile_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* Align boxes to the left */
  margin: 0 -10px;
}

/* Card Container */
.staff_profile_card_container {
  width: 100%;
  max-width: 50%;
  padding: 10px;
  box-sizing: border-box;
}

/* Individual Card */
.staff_profile_card {
  display: flex;
  border: 2px solid #ff5722;
  border-radius: 10px;
  background-color: #ffffff;
  height: 130px;
  margin-bottom: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Image Section */
.staff_profile_image_section {
  background: rgba(3, 169, 244, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.staff_profile_image {
  background: rgb(219, 238, 246);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.staff_profile_img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

/* Details Section */
.staff_profile_details {
  padding: 10px;
  width: 70%;
  color: #333;
  font-size: 1.1em;
  /* Increased font size for details */
  line-height: 1.5;
  /* Improved line spacing */
  text-transform: uppercase;
}

.staff_profile_name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 1.2em;
  /* Larger font size for staff name */
  color: #000;
}

.staff_profile_divider {
  border: 0;
  border-top: 1px solid #ff5722;
  margin: 5px 0;
}

.staff_profile_details p {
  margin: 5px 0;
  font-size: 1em;
  /* Slightly larger paragraph text */
}

.staff_profile_details strong {
  font-weight: bold;
  color: #555;
}

.StudentSubject-container {
  padding: 15px;
  background: linear-gradient(120deg, #f6d365, #fda085);
  font-family: "Arial", sans-serif;
  border-radius: 8px;
}

.StudentSubject-row {
  background: #fff;
  border: 2px solid #ff9f43;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.StudentSubject-info {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.StudentSubject-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #ffa502;
  background-color: #ffeaa7;
}

.StudentSubject-details h3 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #2f3542;
}

.StudentSubject-details p {
  margin: 0;
  color: #57606f;
  font-size: 12px;
}

.StudentSubject-box {
  border: 1px solid #dcdde1;
  border-radius: 8px;
  padding: 8px;
  flex: 1 1;
  min-width: 200px;
  background: #f1f2f6;
}

.StudentSubject-box h4 {
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: bold;
  color: #3742fa;
}

.StudentSubject-subjects {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.StudentSubject-subject {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 15px;
  text-transform: capitalize;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.StudentSubject-static {
  background-color: #2ed573;
  color: white;
  cursor: default;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.StudentSubject-selected {
  background-color: #2f3542;
  color: #f1f2f6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.StudentSubject-unselected {
  background-color: #ff6b81;
  color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.StudentSubject-unselected:hover {
  transform: scale(1.03);
}

.StudentSubject-update-button {
  background: linear-gradient(120deg, #74b9ff, #0984e3);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.StudentSubject-update-button:hover {
  background: linear-gradient(120deg, #0984e3, #74b9ff);
  transform: translateY(-1px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Container styling */
.AlternateSubject-container {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Alternative Subject Box */
.AlternateSubject-subject-box {
  background-color: #f1c40f;
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #f39c12;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AlternateSubject-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.AlternateSubject-subject-buttons {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.AlternateSubject-subject-button {
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.AlternateSubject-subject-button.active {
  background-color: #e67e22;
  color: #fff;
}

.AlternateSubject-subject-button {
  background-color: #f0f0f0;
  color: #555;
}

.AlternateSubject-subject-button:hover {
  background-color: #f39c12;
}

/* Row Box Styling */
.AlternateSubject-row-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #8e44ad;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Row Container styling */
.AlternateSubject-row-container {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

/* Row Styling */
.AlternateSubject-row {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
}

/* Card Styling */
.AlternateSubject-card {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  border: 2px solid;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.AlternateSubject-card-content {
  flex-grow: 1;
}

.AlternateSubject-card.selected {
  background-color: #dff0d8;
  border-color: #28a745;
}

.AlternateSubject-card.not-selected {
  background-color: #f2dede;
  border-color: #dc3545;
}

.AlternateSubject-status {
  font-size: 24px;
  font-weight: bold;
  color: green;
}

.AlternateSubject-card.not-selected .AlternateSubject-status {
  color: red;
}

/* Full-width Submit Button */
.AlternateSubject-submit-button {
  width: 100%;
  /* Full width */
  padding: 5px;
  background-color: #2980b9;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.AlternateSubject-submit-button:hover {
  background-color: #3498db;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Main container styling */
.employee-list {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.employee-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
  font-weight: bold;
}

/* Table styling */
.employee-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.employee-table th,
.employee-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.employee-table th {
  background-color: #f4f4f4;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.employee-table tr:hover {
  background-color: #f9f9f9;
}

.employee-table td {
  color: #555;
  font-size: 15px;
}

/* Button styling */
.employee-btn {
  padding: 6px 12px;
  margin: 0 4px;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.employee-btn.view {
  background-color: #007bff;
}

.employee-btn.edit {
  background-color: #28a745;
}

.employee-btn.delete {
  background-color: #dc3545;
}

.employee-btn:hover {
  filter: brightness(85%);
}

.view-payroll {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 800px;
  background-color: #f9f3e7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header Flexbox for Title and Dropdown */
.payroll-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.payroll-title h2 {
  margin: 0;
  font-size: 24px;
  color: #ff7f50;
}

.month-selector {
  display: flex;
  align-items: center;
}

.select-month {
  padding: 5px;
  border: 1px solid #007acc;
  border-radius: 4px;
  background-color: #eaf4ff;
  color: #00509e;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.select-month:hover {
  border-color: #00509e;
}

/* Grid layout for Payroll Details */
.payroll-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 20px;
}

.payroll-item {
  padding: 10px;
  background-color: #d4f1f9;
  border: 2px solid #007acc;
  border-radius: 8px;
  font-size: 14px;
  color: #00509e;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Buttons Section */
.view-payroll-buttons {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.view-payroll-button {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.view-payroll-button:nth-child(1) {
  background-color: #28a745;
  /* Green */
  color: white;
}

.view-payroll-button:nth-child(2) {
  background-color: #007bff;
  /* Blue */
  color: white;
}

.view-payroll-button:nth-child(3) {
  background-color: #ffc107;
  /* Yellow */
  color: black;
}

.view-payroll-button:hover {
  transform: scale(1.05);
  filter: brightness(1.1);
}

.employee-btn.add-salary {
  background-color: #4caf50;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 8px;
  width: 100%;
  margin: 20px auto;
  display: block;
  cursor: pointer;
  letter-spacing: 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease;
}

.employee-btn.add-salary:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.employee-btn.add-salary:active {
  background-color: #3e8e41;
  transform: translateY(1px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

/* Page Container */
.add-salary-page {
  padding: 20px;
  background: linear-gradient(145deg, #f5f7fa, #e6e9ef);
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
  color: #333;
}

/* Page Title */
.add-salary-title {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;
  color: #2b3a67;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Form Group - Flex Layout with Equal Width */
.add-salary-form-group {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  /* Space between label, input, and button */
  margin-bottom: 30px;
}

/* Common Style for All Elements */
.add-salary-form-group label,
.add-salary-input-field,
.add-salary-calculate-btn {
  flex: 1 1;
  /* Equal width for all elements */
  text-align: center;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 8px;
}

/* Label Style */
.add-salary-form-group label {
  font-weight: bold;
  background-color: #e6e6e6;
  padding: 0 10px;
  color: #333;
  border: 1px solid #ccc;
  transition: all 0.3s ease;
}

.add-salary-form-group label:hover {
  background-color: #d6d6d6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Input Field Style */
.add-salary-input-field {
  border: 1px solid #ddd;
  padding: 0 10px;
  text-align: center;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.add-salary-input-field:focus {
  border-color: #4caf50;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.5);
  outline: none;
}

/* Button Style */
.add-salary-calculate-btn {
  font-weight: bold;
  background: linear-gradient(145deg, #4caf50, #43a047);
  color: white;
  border: none;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 5px 10px rgba(76, 175, 80, 0.3);
}

.add-salary-calculate-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(76, 175, 80, 0.5);
}

/* Salary Breakdown Section */
.add-salary-salary-breakdown {
  margin-top: 30px;
}

/* Salary Breakdown Title */
.add-salary-salary-breakdown h2 {
  font-size: 20px;
  font-weight: bold;
  color: #2b3a67;
  text-align: center;
  margin-bottom: 15px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Salary Breakdown Table */
.add-salary-breakdown-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.add-salary-breakdown-table th,
.add-salary-breakdown-table td {
  text-align: left;
  padding: 12px 15px;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
}

.add-salary-breakdown-table th {
  background: linear-gradient(145deg, #2b3a67, #34495e);
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.add-salary-breakdown-table td {
  background: #f9f9f9;
  color: #333;
}

.add-salary-breakdown-table tr:nth-child(even) td {
  background: #f1f1f1;
}

.add-salary-breakdown-table tr:hover td {
  background: #e8f5e9;
  transition: background 0.3s ease;
}

.add-salary-breakdown-table td strong {
  color: #4caf50;
  font-size: 18px;
}

.AddManualAttendance-container {
  font-family: "Arial", sans-serif;
  color: #333;
  margin: auto;
  border-radius: 8px;
}

/* Heading */
.AddManualAttendance-heading {
  text-align: center;
  color: #4caf50;
  font-size: 24px;
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

/* Class and Month Selection - Flex Layout */
.AddManualAttendance-selection-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
}

.AddManualAttendance-select {
  flex: 1 1;
}

.AddManualAttendance-select label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.AddManualAttendance-select select {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  width: 100%;
  background-color: #f7f7f7;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
}

/* Table Styling */
.AddManualAttendance-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
}

.AddManualAttendance-table th,
.AddManualAttendance-table td {
  padding: 2px;
  text-align: center;
  border: 1px solid #ddd;
}

.AddManualAttendance-table th {
  background-color: #4caf50;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.AddManualAttendance-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.AddManualAttendance-table input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  max-width: 60px;
  transition: border-color 0.3s ease-in-out;
}

.AddManualAttendance-table input:focus {
  border-color: #4caf50;
  outline: none;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

/* Button Styling */
.AddManualAttendance-button {
  display: block;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(145deg, #4caf50, #45a049);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.AddManualAttendance-button:hover {
  background: linear-gradient(145deg, #45a049, #4caf50);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Full-page background styling with image and gradient overlay */
.login_background {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Login form container with increased width and modern design */
.login_form_container {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  padding: 40px;
  width: 500px;
  /* Increased width */
  text-align: center;
  transform: scale(0.95);
  /* Start slightly smaller */
  transition: all 0.4s ease-in-out;
  animation: fadeIn 1s ease-in-out;
}

.login_form_container:hover {
  transform: scale(1.03);
  /* Slight zoom effect on hover */
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.4);
}

/* Fade-in animation for login container */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
    /* Start with slight downward shift */
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Title and subtitle */
.login_title {
  font-size: 26px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
  animation: slideIn 0.8s ease-in-out;
}

.login_subtitle {
  font-size: 16px;
  color: #666666;
  margin-bottom: 20px;
}

/* Slide-in animation for title */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Input fields with modern design */
.login_input {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px 15px;
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.login_input:focus {
  border-color: #2575fc;
  box-shadow: 0 4px 8px rgba(37, 117, 252, 0.4);
  outline: none;
}

/* Buttons with animations */
.login_button,
.signup_button {
  border: none;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.login_button {
  background-color: #2575fc;
}

.login_button:hover {
  background-color: #1a5ed1;
  transform: translateY(-2px);
  /* Slight upward shift */
}

.signup_button {
  background-color: #ff9933;
  color: #000;
  font-weight: bolder;
}

.signup_button:hover {
  color: white;
  background-color: #4b0f97;
  transform: translateY(-2px);
  /* Slight upward shift */
}

/* Forgot password link */
.forgot_password_link {
  font-size: 14px;
  color: #2575fc;
  text-decoration: none;
  display: block;
  margin-top: 10px;
  margin-bottom: 20px;
}

.forgot_password_link:hover {
  text-decoration: underline;
}

/* Sign up section */
.signup_section {
  margin-top: 20px;
}

.signup_section p {
  font-size: 14px;
  color: #666666;
  margin-bottom: 10px;
}

.Kesariya {
  color: #ff9933;
}

/* Body Background Image */
.bg_login {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-image: url(/static/media/login-bg-2.40f6c19a.jpg);
  /* Add your image URL here */
  background-size: cover;
  /* Cover the entire screen */
  background-position: center center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Prevent repeating the image */
}

/* Container */
.School_Registration_Container {
  max-width: 600px;
  margin: 20px auto;
  padding: 30px;
  border-radius: 15px;
  font-family: "Poppins", sans-serif;
  background: #1632de9e;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  color: white;
  animation: fadeIn 1s ease-in-out;
}

/* Header */
.School_Registration_Header {
  text-align: center;
  margin-bottom: 20px;
}

.School_Registration_Header h1 {
  font-size: 2.5rem;
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  animation: glow 1.5s infinite alternate;
}

/* Info Box */
.School_Registration_InfoBox {
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.2);
  padding: 15px;
  border-radius: 10px;
  font-size: 1rem;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Form */
.School_Registration_Form {
  display: flex;
  flex-direction: column;
}

/* Label */
.School_Registration_Label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.1rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

/* Row for two input fields */
.School_Registration_Row {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 15px;
}

/* Input and Select styles */
.School_Registration_Input,
.School_Registration_Select {
  padding: 10px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 100%;
  /* Makes two fields fit in the row */
}

/* Focused Input/Select */
.School_Registration_Input:focus,
.School_Registration_Select:focus {
  outline: none;
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
}

/* Button */
.School_Registration_Button {
  width: 100%;
  margin-top: 10px;
  padding: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(135deg, #ff7eb3, #ff758c);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.School_Registration_Button:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #ff7eb3, 0 0 30px #ff758c,
      0 0 40px #ff758c;
  }

  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #ff7eb3, 0 0 20px #ff758c,
      0 0 30px #ff7eb3;
  }
}

/* Background Styling with an Image */
.loginSession_Settings-background {
  background-image: url(/static/media/login-bg-3.f65be0a1.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

/* Optional Overlay for Better Contrast */
.loginSession_Settings-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

/* Container Styling */
.loginSession_Settings-container {
  max-width: 900px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.9);
  /* Semi-transparent background */
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  position: relative;
}

.loginSession_Settings-container h2,
.loginSession_Settings-container h3 {
  margin: 10px 0;
  text-align: center;
  color: #007bff;
  font-weight: bold;
}

.loginSession_Settings-sessionInputs {
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 20px;
}

.loginSession_Settings-sessionInputs > div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.loginSession_Settings-sessionInputs label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #0056b3;
}

.loginSession_Settings-sessionInputs input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #007bff;
  border-radius: 6px;
  outline: none;
  background: #e6f7ff;
  color: #003366;
  transition: all 0.3s;
}

.loginSession_Settings-sessionInputs input:hover,
.loginSession_Settings-sessionInputs input:focus {
  border-color: #0056b3;
  background: #cce7ff;
}

.loginSession_Settings-boardMedium {
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 20px;
}

.loginSession_Settings-boardMedium select {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #007bff;
  border-radius: 6px;
  outline: none;
  background: #e6f7ff;
  color: #003366;
  transition: all 0.3s;
}

.loginSession_Settings-boardMedium select:hover,
.loginSession_Settings-boardMedium select:focus {
  border-color: #0056b3;
  background: #cce7ff;
}

.loginSession_Settings-classSettings {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  gap: 10px;
  background: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 12px;
}

.loginSession_Settings-classButton {
  padding: 5px;
  font-size: 14px;
  text-align: center;
  background-color: #ffeeba;
  border: 1px solid #ffcc00;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s;
  color: #7a5700;
  font-weight: bold;
}

.loginSession_Settings-classButton:hover {
  background-color: #ffd966;
  border-color: #ffb300;
}

.loginSession_Settings-classButton.active {
  background-color: #28a745;
  color: #fff;
  border-color: #218838;
}

.loginSession_Settings-submitSection {
  text-align: center;
  margin-top: 10px;
}

.loginSession_Settings-submitButton {
  padding: 12px;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.loginSession_Settings-submitButton:hover {
  background: linear-gradient(135deg, #0056b3, #003d80);
  transform: translateY(-2px);
}

/* Tab Container */
.tab-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.tab-button.active {
  background-color: #0056b3;
  transform: scale(1.1);
}

.tab-button:hover {
  background-color: #0056b3;
}

/* Notice Content */
.notice-content {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.notice-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.notice-item {
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.notice-item p {
  font-size: 16px;
  color: #555;
  margin: 0;
}

.notice-item small {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #777;
  text-align: right;
}

/* Action Buttons */
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.actions .btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.actions .btn-primary {
  background-color: #007bff;
  color: #fff;
}

.actions .btn-primary:hover {
  background-color: #0056b3;
}

.actions .btn-success {
  background-color: #28a745;
  color: #fff;
}

.actions .btn-success:hover {
  background-color: #218838;
}

/* Global School Section */
.add_notice-section {
  margin-bottom: 20px;
}

.add_notice-checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.add_notice-checkbox {
  margin-right: 8px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.add_notice-checkbox-label {
  margin-left: 5px;
  font-size: 16px;
  color: #333;
  font-weight: normal;
}

.add_notice-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.add_notice-card.show {
  opacity: 1;
  visibility: visible;
}

.add_notice-card h5 {
  font-size: 18px; /* Adjusted font size */
  color: #333;
  margin-bottom: 8px;
}

/* Student Section */
.add_notice-student-options {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
  gap: 8px; /* Reduced gap */
  margin-bottom: 12px;
}

.add_notice-option-btn {
  padding: 6px 12px; /* Reduced padding */
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  background-color: #fff;
  color: #333;
  font-size: 14px; /* Adjusted font size */
  font-weight: normal; /* Removed boldness */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.add_notice-option-btn:hover {
  background-color: #f0f0f0;
  color: #007bff;
}

.add_notice-option-btn.active {
  background-color: #007bff;
  color: #fff;
}

.add_notice-selected-text {
  font-size: 14px;
  color: #777; /* Lighter color */
  margin-top: 5px;
}

.add_notice-selected-student-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
}

.add_notice-selected-student-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.add_notice-student-img {
  width: 50px; /* Reduced size */
  height: 50px; /* Reduced size */
  border-radius: 50%;
  margin-right: 12px;
}

.add_notice-student-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.add_notice-student-details {
  flex: 1 1;
}

.add_notice-student-details h5 {
  font-size: 16px; /* Adjusted font size */
  margin-bottom: 5px;
}

.add_notice-student-details p {
  font-size: 14px; /* Adjusted font size */
  margin-bottom: 3px;
  color: #777; /* Lighter color */
}

.add_notice-student-roll-no {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.add_notice-roll-no-circle {
  width: 28px; /* Reduced size */
  height: 28px; /* Reduced size */
  border-radius: 50%;
  background-color: #f0f0f0;
  text-align: center;
  line-height: 28px;
  font-size: 14px; /* Reduced font size */
  color: #333;
  margin-right: 8px;
}

.add_notice-student-roll-no h6 {
  font-size: 12px; /* Reduced font size */
  color: #777; /* Lighter color */
}

/* Class Section */
.add_notice-class-options {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
  gap: 8px; /* Reduced gap */
  margin-bottom: 12px;
}

.add_notice-selected-class-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
}

.add_notice-selected-class-item {
  display: flex;
  margin-bottom: 12px;
}

.add_notice-class-info {
  flex: 1 1;
  margin-right: 12px;
}

.add_notice-class-header {
  text-align: center;
  background-color: #007bff;
  padding: 10px;
  border-radius: 6px; /* Reduced border-radius */
  color: #fff;
}

.add_notice-class-header h3 {
  margin: 0;
  font-size: 16px; /* Reduced font size */
}

.add_notice-class-details {
  padding: 10px;
}

.add_notice-class-details p {
  font-size: 14px;
  color: #777; /* Lighter color */
}

.add_notice-class-checkbox {
  text-align: center;
}

/* Staff Section */
.add_notice-staff-options {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
  gap: 8px; /* Reduced gap */
  margin-bottom: 12px;
}

/* General Styling */
.add_notice-container {
  padding: 20px; /* Reduced padding */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add_notice-checkbox-container {
  background-color: #e6f7ff;
  padding: 10px; /* Reduced padding */
  border-radius: 8px;
}

.add_notice-checkbox-label {
  font-size: 16px;
  color: #007bff;
  font-weight: normal; /* Removed boldness */
}
/* General Container */
.add_notice-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Section Styles */
.add_notice-section {
  margin-bottom: 20px;
}

/* Checkbox Container (Gap between checkbox and label) */
.add_notice-checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer; /* Change cursor to pointer */
  margin-bottom: 10px;
}

.add_notice-checkbox {
  width: 20px; /* Adjustable size */
  height: 20px;
  background-color: #ddd;
  border-radius: 4px;
  margin-right: 15px; /* Increased space between checkbox and label */
  transition: background-color 0.3s ease-in-out;
}

.add_notice-checkbox-label {
  font-size: 16px;
  color: #333;
  font-weight: normal;
}

.add_notice-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.add_notice-card.show {
  opacity: 1;
  visibility: visible;
}

/* Student Section Styles */
.add_notice-student-options,
.add_notice-class-options {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
  gap: 8px;
}

.add_notice-option-btn {
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}

.add_notice-option-btn.active {
  background-color: #007bff;
  color: #fff;
}

.add_notice-selected-text {
  font-size: 14px;
  color: #777;
}
/* CSS for the Add Notice component */

.notice_add-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.notice_add-col {
  padding: 15px;
  flex: 1 1 50%; /* Updated for col-6 layout */
  box-sizing: border-box;
}

.notice_add-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notice_add-heading {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.notice_add-input,
.notice_add-file {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.notice_add-textarea {
  width: 100%;
  padding: 15px; /* Increased padding for larger size */
  height: 120px; /* Added height for a larger box */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.notice_add-input:focus,
.notice_add-textarea:focus,
.notice_add-file:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.notice_add-full-width {
  flex: 1 1 100%;
}
.Comment {
  padding: 20px;
  background: linear-gradient(
    to right,
    #f0f8ff,
    #e6f7ff
  ); /* Gradient background */
  font-family: "Arial", sans-serif;
  border-radius: 10px;
}
.Comment {
  padding: 10px;
  background: #f0f8ff; /* Light background */
  font-family: "Arial", sans-serif;
  border-radius: 5px;
}

.Comment-header h1 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #0056b3;
  margin-bottom: 10px;
}

.Comment-description {
  text-align: center;
  font-size: 14px;
  color: #007bff;
  margin-bottom: 15px;
  line-height: 1.5;
}

.Comment-list {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.Comment-item {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.Comment-item:hover {
  border-left: 4px solid #28a745;
}

.Comment-item-header h5 {
  color: #6c757d;
  font-size: 12px;
  margin-bottom: 4px;
}

.Comment-item-content {
  margin-bottom: 8px;
}

.Comment-item-title {
  font-size: 18px;
  font-weight: 600;
  color: #17a2b8;
  margin-bottom: 8px;
}

.Comment-item-body {
  font-size: 14px;
  color: #495057;
  line-height: 1.4;
  min-height: 20px;
}

.Comment-item-footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #6c757d;
  margin-top: 10px;
}

/* Simplified Reply Button Styles */
.Comment-reply-button {
  display: inline-block;
  background: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.Comment-recent-reply {
  padding: 10px;
  border: 1px solid #cce5ff;
  border-radius: 5px;
  background: #e9f7ff;
}

.Comment-recent-reply h6 {
  font-size: 14px;
  color: #0056b3;
  margin-bottom: 8px;
}

.Comment-reply-item {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 6px 0;
  border-bottom: 1px solid #dee2e6;
}

.Comment-reply-item:last-child {
  border-bottom: none;
}

.Comment-reply-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ffc107;
}

.Comment-reply-details {
  flex: 1 1;
}

.Comment-reply-details h5 {
  font-size: 12px;
  color: #28a745;
  margin-bottom: 4px;
}

.Comment-reply-details h6 {
  font-size: 12px;
  color: #495057;
}

.Comment-reply-item:hover {
  background-color: #f1f9ff;
}
.notification-container {
  padding: 15px;
  background-color: #f1f3f5;
  border-radius: 8px;
}

.intro-text {
  text-align: center;
  font-size: 16px;
  color: #444;
  margin-bottom: 20px;
  font-weight: 500;
  line-height: 1.6;
}

.notifications {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  gap: 10px;
}

.notification {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease-in-out;
  max-height: 250px; /* Reduced height */
  overflow: hidden; /* Prevents overflow */
}

.notification:hover {
  transform: translateY(-5px);
  box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.2);
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #0288d1;
  font-weight: 600;
}

.notification-title {
  font-size: 15px;
  color: #333;
  margin: 0;
  font-weight: bold;
  color: #1a73e8; /* Blue shade */
}

.notification-content {
  margin-bottom: 10px;
  color: #555;
  font-size: 13px;
  line-height: 1.4;
}

.notification-footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #888;
}

.notification-footer span {
  margin-top: 5px;
}

.notification_button {
  width: 100%;
  background-color: #ff5733; /* Bright orange for contrast */
  color: white;
  font-size: 16px;
  padding: 5px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.notification_button:hover {
  background-color: #e84c1f; /* Darker orange on hover */
  transform: scale(1.05);
}

.send-message {
  margin-top: 20px;
  text-align: center;
}

/* Adding small shadow to the button */
.notification_button {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.notification_button:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.Accessories-button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.Accessories-button--primary {
  background-color: #007bff;
  color: #fff;
}

.Accessories-button--primary:hover {
  background-color: #0056b3;
}

.Accessories-button--secondary {
  background-color: #6c757d;
  color: #fff;
}

.Accessories-button--secondary:hover {
  background-color: #495057;
}

.Accessories-button--success {
  background-color: #28a745;
  color: #fff;
}

.Accessories-button--success:hover {
  background-color: #1e7e34;
}

.Accessories-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.Accessories-actions {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  margin: 20px 0;
}

.Accessories-action {
  width: 100%;
}

.Accessories-container {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.Accessories-cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  gap: 15px;
}

.Accessories-card {
  padding: 20px;
  border-radius: 8px;
  background-color: #f7f7f7;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.Accessories-card-title {
  font-size: 18px;
  font-weight: bold;
}

.Accessories-card-value {
  font-size: 16px;
  color: #333;
}

.Accessories-card-image img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.Accessories-deep-purple {
  background-color: #6a1b9a;
}

.Accessories-navy-blue {
  background-color: #004d73;
}

.Accessories-green {
  background-color: #28a745;
}

.Accessories-grey {
  background-color: #6c757d;
}

.Accessories-red {
  background-color: #dc3545;
}

.Accessories-amber {
  background-color: #ffc107;
}

.Accessories-blue {
  background-color: #007bff;
}

.Accessories-orange {
  background-color: #fd7e14;
}

.dropdown-container-flex {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; /* Space between the heading and dropdown */
  width: 300px;
  margin: 2px auto;
  font-family: Arial, sans-serif;
}
.styled-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  -webkit-appearance: none;
          appearance: none; /* Remove default arrow for better control */
}

.styled-select:hover {
  border-color: #007bff;
}

.styled-select:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
h3 {
  margin: 0;
  font-size: 16px;
  white-space: nowrap; /* Prevent wrapping for the heading */
}

